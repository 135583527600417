export default class Parameter {
    constructor(params) {
        this.id = params.id;
        this.component_id = params.component_id;
        this.name = params.name;
        this.parameter_code = params.parameter_code;
        this.type = params.type;
        this.values = params.values || null;
        this.activity = params.activity;
    }
}