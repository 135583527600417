<template>
  <v-card v-if="active_component_id && parameters.length !== 0">
    <v-card-title>Превью формы</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
            v-for="parameter in parameters"
            :key="parameter.id"
            md="12"
            class="pb-0"
        >
          <v-text-field
              v-if="parameter.type === 'INPUT'"
              :label="parameter.name"

          ></v-text-field>
          <v-select
              v-else-if="parameter.type === 'DIRECTORY'"
              :label="parameter.name"
              :items="parameter.values"
              item-text="values"
              item-value="id"
          ></v-select>

          <v-select
            v-else-if="parameter.type === 'DIRECTORY_PHOTO'"
            :label="parameter.name"
            :items="parameter.values"
          >
            <template v-slot:selection="{ item }">
              <img
                alt="Изображение справочника"
                :src="item.img_url"
              >
            </template>
            <template v-slot:item="{ item }">
              <img
                alt="Изображение справочника"
                :src="item.img_url"
              >
            </template>
          </v-select>

          <v-select
            v-else-if="parameter.type === 'TC_CALCULATOR'"
            :label="parameter.name"
            :items="parameter.values"
            item-text="name"
            item-value="id"
            multiple
          ></v-select>

          <div v-else-if="parameter.type === 'MULTI_PHOTO'">
            <v-file-input
              :label="parameter.name"
              counter
              show-size
              multiple
            ></v-file-input>
          </div>

          <v-file-input
              v-else-if="parameter.type === 'FILE'"
              :label="parameter.name"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FormPreview",
  computed: {
    ...mapGetters({
      active_component_id: 'components/getActiveComponentId',
      parameters: 'parameters/getParameters'
    })
  }
}
</script>

<style scoped>

</style>
