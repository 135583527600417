<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col v-if="active_object_type_id"
               @click="dialog = true"
               md="12">
          <div class="add-list-item">Добавить компонент</div>
        </v-col>
        <v-col v-for="component in components"
               :key="component.id"
               @click="getParameters(component.object_type_id, component.id)"
               md="12">
          <div class="list-item"
               :class="{active: active_component_id === component.id}">
            <div class="list-item-name">{{ component.name }}</div>
            <div class="list-item-description">Код: {{ component.component_code }} |
              {{ component.is_form ? "Является" : "Не является" }} формой
            </div>

            <v-btn-toggle active-class="active-none" class="mt-3">

              <v-btn
                  color="blue"
                  x-small
                  @click="updateComponent(component)"
              >
                <v-icon small color="white">
                  mdi-text-box-edit
                </v-icon>
              </v-btn>

              <v-btn
                  :color="component.activity ? 'blue' : 'grey'"
                  x-small
                  @click="toggleActivity(component)"
              >
                <v-icon small color="white">
                  mdi-power
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
        v-model="updateDialog"
        max-width="600px"
    >
      <update-component-form
          :component="selectedElement"
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <create-component-form @success-request="dialog = false"/>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import CreateComponentForm from "@/components/ObjectType/CreateComponentForm";
import UpdateComponentForm from "@/components/ObjectType/UpdateComponentForm";

export default {
  name: "ComponentsList",
  components: {UpdateComponentForm, CreateComponentForm},
  data() {
    return {
      dialog: false,
      updateDialog: false,
      selectedElement: null
    }
  },
  computed: {
    ...mapGetters({
      active_object_type_id: 'object_types/getActiveObjectTypeId',
      active_component_id: 'components/getActiveComponentId',
      components: 'components/getComponents'
    })
  },
  methods: {
    getParameters(object_type_id, component_id) {
      this.$store.commit('components/SET_ACTIVE_COMPONENT_ID', component_id);
      this.$store.dispatch('parameters/getComponentParameters', {
        object_type_id: object_type_id,
        component_id: component_id
      })
    },
    updateComponent(component) {
      this.selectedElement = component;
      this.updateDialog = true;
    },
    toggleActivity(component) {
      if (component.activity) this.$store.dispatch('components/deactivateComponent', component.id)
          .then(() => this.$store.dispatch('components/getComponents', component.object_type_id))
      else this.$store.dispatch('components/activateComponent', component.id)
          .then(() => this.$store.dispatch('components/getComponents', component.object_type_id))
    }
  }
}
</script>

<style scoped>

</style>
