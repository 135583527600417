import * as API from '@/services/API'
import Directory from "@/models/Directory";

export default {
    getDirectories() {
        return new Promise((resolve ,reject) => {
            API.apiClient.get(`/api/catalogs/directories`)
                .then(response => resolve(response.data.data
                    .map(directory => new Directory(directory))))
                .catch(error => reject(error));
        })
    },
    getDirectory(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/directories/${id}`)
                .then(response => resolve(new Directory(response.data.data)))
                .catch(error => reject(error));
        })
    },
    createDirectory(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`/api/catalogs/directories`, payload)
                .then(() => resolve())
                .catch(error => reject(error));
        })
    },
    updateDirectory({directory_id, payload}) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`/api/catalogs/directories/${directory_id}`, payload)
                .then(() => resolve())
                .catch(error => reject(error));
        })
    },
    deleteDirectory(directory_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.delete(`/api/catalogs/directories/${directory_id}`)
                .then(() => resolve())
                .catch(error => reject(error));
        })
    }
}