<template>
  <v-card>
    <v-card-title>Создать параметр</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.params.name"
            :rules="nameRules"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.params.parameter_code"
            :rules="parameterCodeRules"
            label="Символьный код"
            required
        ></v-text-field>
        <v-select
          v-model="payload.params.type"
          :rules="typeRules"
          label="Тип параметра"
          :items="select_options"
          required
        ></v-select>
        <v-select
          v-if="payload.params.type === 'DIRECTORY'"
          v-model.number="payload.params.directory_id"
          label="Справочник"
          :items="directories.filter(item => item.type === 1)"
          item-text="name"
          item-value="id"
          required
        ></v-select>
        <v-select
            v-if="payload.params.type === 'DIRECTORY_PHOTO'"
            v-model.number="payload.params.directory_id"
            label="Справочник"
            :items="directories.filter(item => item.type === 2)"
            item-text="name"
            item-value="id"
            required
        ></v-select>
        <v-select
          v-if="payload.params.type === 'TC_CALCULATOR' || payload.params.type === 'PHOTO_DEFECTS'"
          v-model.number="payload.params.directory_id"
          label="Объект оценки"
          :items="evaluationObjectsList"
          item-text="name"
          item-value="id"
          required
        ></v-select>
        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "CreateParameterForm",
  data() {
    return {
      payload: {
        params: {
          name: null,
          parameter_code: null,
          type: null,
          directory_id: null
        }
      },
      select_options: [
        {value: 'INPUT', text: 'Текстовое поле'},
        {value: 'DIRECTORY', text: 'Справочник'},
        {value: 'FILE', text: 'Файл'},
        {value: 'DIRECTORY_PHOTO', text: 'Фото-справочник'},
        {value: 'TC_CALCULATOR', text: 'Техническое состояние'},
        {value: 'MULTI_PHOTO', text: 'Мультифото'},
        {value: 'PHOTO_DEFECTS', text: 'Фото дефект'}
      ],
      valid: false,
      nameRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      parameterCodeRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      typeRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  computed: {
    ...mapGetters({
      object_type_id: 'object_types/getActiveObjectTypeId',
      component_id: 'components/getActiveComponentId',
      directories: 'directories/getDirectories'
    }),
    ...mapState({
      evaluationObjectsList: state => state.evaluationObjects.objectsList
    })
  },
  mounted() {
    this.$store.dispatch('directories/getDirectories');
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.payload.object_type_id = this.object_type_id;
        this.payload.component_id = this.component_id;
        this.$store.dispatch('parameters/createParameter', this.payload)
            .then(() => {
              const payload = {
                component_id: this.component_id,
                object_type_id: this.object_type_id
              }
              this.$store.dispatch('parameters/getComponentParameters', payload);
              this.$emit('success-request');

              this.payload.params = {
                name: null,
                parameter_code: null,
                type: null
              }

              this.$refs.form.resetValidation()
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
