<template>
  <v-row>
    <v-col md="12">
      <construction-types-list />
    </v-col>

    <v-dialog
        v-model="createDialog"
        max-width="600px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
            @click="createDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <construction-type-create-form @success-request="createDialog = false" />
    </v-dialog>

  </v-row>
</template>

<script>
import ConstructionTypesList from "@/components/Construction/ConstructionTypesList";
import ConstructionTypeCreateForm from "@/components/Construction/ConstructionTypeCreateForm";
export default {
  name: "ConstructionTypesListPage",
  components: {ConstructionTypeCreateForm, ConstructionTypesList},
  data() {
    return {
      createDialog: false
    }
  },
  mounted() {
    this.$store.dispatch('constructionTypes/getConstructionTypes');
  }
}
</script>

<style scoped>

</style>