<template>
  <v-card>
    <v-card-title>Изменить компонент</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.component_code"
            :rules="notNullRule"
            label="Cимвольный код"
            required
        ></v-text-field>
        <v-checkbox
            v-model="payload.is_form"
            label="Является формой">
        </v-checkbox>
        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UpdateComponentForm",
  props: ['component', 'dialogState'],
  data() {
    return {
      valid: false,
      payload: {
        name: null,
        component_code: null,
        is_form: false
      },
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  watch: {
    dialogState: function (state) {
      if (state) this.fillFields()
    }
  },
  mounted() {
    this.fillFields();
  },
  methods: {
    fillFields() {
      this.payload.name = this.component.name;
      this.payload.component_code = this.component.component_code;
      this.payload.is_form = this.component.is_form
    },
    submitForm() {
      this.$store.dispatch('components/updateComponent', {
        componentId: this.component.id,
        payload: this.payload,
      })
          .then(() => {
            this.$store.dispatch('components/getComponents', this.component.object_type_id);
            this.$emit('success-request');

            this.payload = {
              name: null,
              component_code: null,
              is_form: false
            }

            this.$refs.form.resetValidation()
          })
    }
  }
}
</script>

<style scoped>

</style>
