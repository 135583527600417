import * as API from '@/services/API'
import {Engineer} from "@/models/Engineer";

export default {
    getEngineers() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/api/catalogs/engineers')
                .then(response => {
                    resolve(response.data.data.map(engineer => new Engineer(engineer)))
                })
                .catch(error => reject(error));
        })
    },
    getEngineer(engineer_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/engineers/${engineer_id}`)
                .then(response => {
                    resolve(new Engineer(response.data.data))
                })
                .catch(error => reject(error));
        })
    },
    createEngineer(payload) {
        return API.apiClient.post('/api/catalogs/engineers', payload);
    },
    updateEngineer({engineer_id, payload}) {
        return API.apiClient.patch(`/api/catalogs/engineers/${engineer_id}`, payload);
    },
    deleteEngineer(engineer_id) {
        return API.apiClient.delete(`/api/catalogs/engineers/${engineer_id}`);
    }
}