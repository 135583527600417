<template>
  <v-col md="12">
    <v-card elevation="2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Наименование</th>
                <th class="text-left">Город</th>
                <th class="text-left">Исполнитель</th>
                <th class="text-left">Основание обследования</th>
                <th class="text-left">Заказчик</th>
                <th class="text-left">Дата начала</th>
                <th class="text-left">Дата окончания</th>
                <th class="text-left">Управление</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in projects"
                :key="item.id"
              >
                <td>{{item.id}}</td>
                <td>{{item.name}}</td>
                <td>{{item.city}}</td>
                <td>{{item.executor}}</td>
                <td>{{item.basis}}</td>
                <td>{{item.customer}}</td>
                <td>{{item.start_at}}</td>
                <td>{{item.finish_at}}</td>
                <td>
                  <v-icon
                      @click="updateProject(item.id)"
                      class="cursor-pointer mr-2"
                  >mdi-text-box-edit
                  </v-icon>
                  <v-icon
                      @click="deleteProject(item.id)"
                      class="cursor-pointer"
                  >mdi-delete
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

    </v-card>

    <v-dialog
        v-model="createDialog"
        max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <create-project-form @success-request="createDialog = false" />
    </v-dialog>

    <v-dialog
      v-model="updateDialog"
      max-width="600px"
    >
      <update-project-form
          @success-request="updateDialog = false"
          :dialog-state="updateDialog"
          :project-id="updateProjectId"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import CreateProjectForm from "@/components/Project/CreateProjectForm";
import UpdateProjectForm from "@/components/Project/UpdateProjectForm";
export default {
  name: "ProjectsListPage",
  components: {UpdateProjectForm, CreateProjectForm},
  computed: {
    ...mapGetters({
      projects: 'projects/getProjects'
    })
  },
  data() {
    return {
      createDialog: false,
      updateDialog: false,
      updateProjectId: null
    }
  },
  mounted() {
    this.$store.dispatch('projects/getProjects');
  },
  methods: {
    updateProject(id) {
      this.updateProjectId = id;
      this.updateDialog = true;
    },
    deleteProject(id) {
      this.$store.dispatch('projects/deleteProject', id)
          .then(() => {
            this.$store.dispatch('projects/getProjects');
          })
    }
  }
}
</script>

<style scoped>

</style>