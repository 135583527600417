import VueRouter from "vue-router";
import middlewarePipeline from "@/router/middlewarePipeline";
import auth from "@/router/middleware/auth";
import guest from "@/router/middleware/guest";
import store from "@/store";
import MainPage from "@/views/MainPage";
import ProjectsListPage from "@/views/Project/ProjectsListPage";
import ObjectTypesListPage from "@/views/ObjectType/ObjectTypesListPage";
import InventoryObjectsListPage from "@/views/InventoryObject/ObjectsListPage";
import CreateInventoryObjectPage from "@/views/InventoryObject/CreateObjectPage";
import InventoryObjectPage from "@/views/InventoryObject/ObjectPage";
import DirectoriesListPage from "@/views/Directory/DirectoriesListPage";
import DirectoryPage from "@/views/Directory/DirectoryPage";
import EngineersListPage from "@/views/Engineer/EngineersListPage";
import DevicesListPage from "@/views/Device/DevicesListPage";
import ProtocolsListPage from "@/views/Protocol/ProtocolsListPage";
import UsersListPage from "@/views/Users/UsersListPage";
import LoginPage from "@/views/LoginPage";
import NotFoundPage from "@/views/NotFoundPage";
import CategoriesListPage from "@/views/InitialData/CategoriesListPage";
import ObjectsListPage from "@/views/InitialData/ObjectsListPage";
import ObjectPage from "@/views/InitialData/ObjectPage";
import ConstructionTypesListPage from "@/views/Construction/ConstructionTypesListPage";
import EngineerResultsPage from "@/views/Reports/EngineerResultsPage.vue";
import EvaluationObjectPage from "@/views/TCCalculator/EvaluationObjectPage";
import EvaluationObjectsListPage from "@/views/TCCalculator/EvaluationObjectsListPage.vue";
import EngineerDetailResultsPage from "@/views/Reports/EngineerDetailResultsPage.vue";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Авторизация',
            component: LoginPage,
            meta: { middleware: [guest], layout: 'Login' }
        },
        {
            path: '/404',
            alias: '*',
            name: 'Страница не найдена',
            component: NotFoundPage,
            meta: {middleware: [guest], layout: 'Login'}
        },
        {
            path: '/',
            name: 'Главная',
            component: MainPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/projects',
            name: 'Список проектов',
            component: ProjectsListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/object-types',
            name: 'Справочник типов объектов',
            component: ObjectTypesListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/inventory-objects',
            name: 'Объекты инвентаризации',
            component: InventoryObjectsListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/create-inventory-object',
            name: 'Создать объект инвентаризации',
            component: CreateInventoryObjectPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/inventory-object/:id',
            name: 'Объект инвентаризации',
            component: InventoryObjectPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/directories',
            name: 'Справочники',
            component: DirectoriesListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/directory/:id',
            name: 'Справочник',
            component: DirectoryPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/engineers',
            name: 'Инженеры',
            component: EngineersListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/devices',
            name: 'Устройства',
            component: DevicesListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/protocols',
            name: 'Протоколы',
            component: ProtocolsListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/users',
            name: 'Пользователи',
            component: UsersListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/initial-data-categories',
            name: 'Категории исходных данных',
            component: CategoriesListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/initial-data-object',
            name: 'Объекты исходных данных',
            component: ObjectsListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/initial-data-object/:id',
            name: 'Объект исходных данных',
            component: ObjectPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/construction-types',
            name: 'Типы конструкций',
            component: ConstructionTypesListPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/reports/engineers-results-report',
            name: 'Сводный отчет по инженерам',
            component: EngineerResultsPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/reports/engineers-detail-results-report',
            name: 'Детальный отчет по инженерам',
            component: EngineerDetailResultsPage,
            meta: { middleware: [auth], layout: 'Main' }
        },
        {
            path: '/evaluation-objects',
            name: 'Объекты оценки',
            component: EvaluationObjectsListPage,
            meta: {middleware: [auth], layout: 'Main'}
        },
        {
            path: '/evaluation-object/:id',
            name: 'Объект оценки',
            component: EvaluationObjectPage,
            meta: {middleware: [auth], layout: 'Main'}
        }
    ]
})

router.beforeEach((to, from, next) => {
    const {middleware} = to.meta;
    const context = { to, from, next, store };

    if (!middleware) {
        return next();
    }
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

export default router
