export default class Directory {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.type = params.type;
    }

    getTypeLabel() {
        switch (this.type) {
            case 1:
                return 'Текстовый'
            case 2:
                return 'Фото'
        }
    }
}