import * as API from '@/services/API'
import {EngineerReportItem} from "@/models/Reports/EngineerReportItem";
import {EngineerDetailReportItem} from "@/models/Reports/EngineerDetailReportItem";

export default {
    getEngineerResultsReport(start_period, end_period) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/reports/engineer-results-report?start_period=${start_period}&end_period=${end_period}`)
                .then(response => resolve(response.data.data.map(item => new EngineerReportItem(item))))
                .catch(error => reject(error));
        })
    },
    getEngineerDetailResultsReport(start_period, end_period, user_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/reports/engineer-results-detail-report?start_period=${start_period}&end_period=${end_period}&user_id=${user_id}`)
                .then(response => resolve(response.data.data.map(item => new EngineerDetailReportItem(item))))
                .catch(error => reject(error));
        })
    }
}
