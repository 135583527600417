<template>
  <div
    v-if="row.value ? row.value.length : false"
    class="photo-defects__container"
  >
    <div
      v-for="defect in processPhotoDefects(row.value)"
      :key="defect.url"
      class="photo-defect">
      <img
        @click="showImage(defect.url)"
        alt=""
        class="photo-defect__img"
        :src="defect.url">

      <span class="photo-defect__label">{{ defect.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ObjectPhotoDefects",
  props: {
    row: {}
  },
  methods: {
    processPhotoDefects(value) {
      const valueArr = value.split(';');
      return valueArr.map(item => {
        const [url, text] = item.split('|')
        return {
          url,
          text
        }
      })
    },
    showImage(image) {
      this.$viewerApi({
        images: [image],
        options: {
          navbar: false,
          title: false,
          toolbar: false
        }
      })
    }
  }
}
</script>

<style scoped>
.photo-defects__container {
  display: flex;
  gap: 10px;
}

.photo-defect {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.photo-defect__img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
}

.photo-defect__label {
  font-size: 14px;
}
</style>