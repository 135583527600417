<template>
  <v-card>
    <v-card-text>
      <h2 class="black--text">
        {{ evaluationObjectName }}
      </h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EvaluationObjectInfo",
  props: {
    evaluationObjectName: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
