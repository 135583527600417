import ComponentsService from "@/services/ComponentsService";

export const namespaced = true;

export const state = {
    components: [],
    loading: false,
    action_loading: false,
    error: null,
    active_component_id: null,
    active_component_form_state: false
}

export const mutations = {
    SET_COMPONENTS(state, components) {
        state.components = components;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ACTION_LOADING(state, loading) {
        state.action_loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_ACTIVE_COMPONENT_ID(state, id) {
        state.active_component_id = id;
    },
    SET_ACTIVE_COMPONENT_FORM_STATE(state, form_state) {
        state.active_component_form_state = form_state
    }
}

export const actions = {
    getComponents({commit}, object_type_id) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ComponentsService.getComponents(object_type_id)
                .then(components => {
                    commit("SET_COMPONENTS", components);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    createComponent({commit}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            ComponentsService.createComponent(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    updateComponent({commit, rootState}, {componentId, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            ComponentsService.updateComponent(rootState.object_types.active_object_type_id, componentId, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    activateComponent({commit, rootState}, componentId) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            ComponentsService.activateComponent(rootState.object_types.active_object_type_id, componentId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    deactivateComponent({commit, rootState}, componentId) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            ComponentsService.deactivateComponent(rootState.object_types.active_object_type_id, componentId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    }
}

export const getters = {
    getComponents: state => {
        return state.components;
    },
    getComponent: state => id => {
        return state.components.find(item => item.id === parseInt(id));
    },
    getActiveComponentId: state => {
        return state.active_component_id;
    },
    getActiveComponentFormState: state =>  {
        return state.active_component_form_state
    }
}

