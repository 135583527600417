import * as API from '@/services/API'
import Component from "@/models/Component";

export default {
    getComponents(object_type_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/object-types/${object_type_id}/components`)
                .then(response => {
                    resolve(response.data.data.map(component => {
                        return new Component(component)
                    }))
                })
                .catch(error => reject(error));
        })
    },
    createComponent(payload) {
        return API.apiClient.post(`/api/catalogs/object-types/${payload.object_type_id}/components`,
            payload.params);
    },
    updateComponent(objectTypeId, componentId, payload) {
        return API.apiClient.patch(`/api/catalogs/object-types/${objectTypeId}/components/${componentId}`, payload);
    },
    activateComponent(objectTypeId, componentId) {
        return API.apiClient.post(`/api/catalogs/object-types/${objectTypeId}/components/${componentId}/activate`);
    },
    deactivateComponent(objectTypeId, componentId) {
        return API.apiClient.post(`/api/catalogs/object-types/${objectTypeId}/components/${componentId}/deactivate`)
    }
}