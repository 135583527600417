<template>
  <v-container class="pagination">
    <v-pagination
      v-model="page"
      :length="meta.last_page" />
  </v-container>
</template>

<script>
export default {
  name: "BasePagination",
  props: {
    meta: {
      type: Object,
      required: true
    },
    mutation: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  computed: {
    page: {
      get() {
        return this.meta.current_page
      },
      set(value) {
        this.$store.commit(this.mutation, value);
        this.$store.dispatch(this.action);
      }
    }
  }
}
</script>

<style scoped>
.pagination {
  max-width: 400px;
}
</style>
