<template>
  <v-card>
    <v-card-title>Добавить объект оценки</v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
      >
        <v-text-field
          v-model="payload.name"
          :rules="notNullRule"
          label="Наименование"
          required
        ></v-text-field>

        <v-btn
          color="primary"
          block
          @click="submitForm()"
        >Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EvaluationObjectForm",
  data() {
    return {
      valid: true,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null
      }
    }
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('evaluationObjects/createEvaluationObject', {
          categoryId: this.$route.params.id,
          payload: this.payload
        })
          .then(() => {
            this.$store.dispatch('evaluationObjects/getEvaluationObjects');
            this.$emit('success-request');
            this.$refs.form.resetValidation();

            this.payload = {
              name: null
            }
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
