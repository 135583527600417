<template>
  <v-row justify="center" align="center">
    <div class="d-flex flex-column align-center">
      <div class="error-404">404</div>
      <div class="page-not-found">Страница не найдена</div>
      <div
          @click="$router.push('/')"
          class="return-link"
      >Вернуться на главную
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>

<style scoped>
.error-404 {
  font-size: 50px;
  font-weight: 700;
}

.page-not-found {
  font-size: 24px;
}

.return-link {
  cursor: pointer;
  color: dodgerblue;
}
</style>