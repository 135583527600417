<template>
  <v-row>
    <v-alert
        :value="isGenerateSuccess"
        type="success"
        dismissible
        class="custom-alert"
    >
      Протокол отправлен на генерацию
    </v-alert>
    <v-alert
        :value="isGenerateError"
        type="error"
        dismissible
        class="custom-alert"
    >
      Ошибка генерации протокола
    </v-alert>
    <v-col md="12" class="mb-4">
      <v-card v-if="object">
        <v-card-title>
          <ObjectState :id="object.state_id" :name="object.state_name"></ObjectState>
          {{ object.name }}
          <v-icon
              @click="updateDialog = true"
              class="cursor-pointer mr-2"
          >mdi-text-box-edit
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
                lg="4"
                md="6"
                xs="12"
            >
              <div class="object-data-name">Адрес</div>
              <div class="object-data mb-3">{{ object.address }}</div>

              <div class="object-data-name">Координаты</div>
              <div class="object-data mb-3">{{ object.coordinates.lat }}, {{ object.coordinates.lon }}</div>

              <div class="object-data-name">Номер по абонентской базе</div>
              <div class="object-data">{{ object.subscriber_base_number ?? 'Не указан' }}</div>

              <div class="object-data-name">Районное управление</div>
              <div class="object-data">{{ object.district ?? 'Не указано' }}</div>
            </v-col>
            <v-col
                lg="2"
                md="6"
                xs="12"
            >
              <div class="object-data-name">Тип объекта</div>
              <div class="object-data mb-3">{{ objectTypeName(object.object_type_id) }}</div>

              <div class="object-data-name">Тип конструкции</div>
              <div class="object-data">{{ object.construction_type.name }}</div>
            </v-col>
            <v-col
                lg="4"
                md="6"
                xs="12"
            >
              <div class="object-data-name">Инженеры</div>
              <div class="object-data mb-3">{{ object.engineers.map(el => el.short_name).join(', ') }}</div>

              <div class="object-data-name">Оборудование</div>
              <div class="object-data">{{ object.devices.join(', ') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                  v-if="object != undefined && (object.object_type_id == 7 || object.object_type_id == 3 || object.object_type_id == 8)"
                  class="float-end mr-2"
                  @click="generateProtocol">
                Сгенерировать протокол
              </v-btn>
              <v-btn
                  v-if="object.state_id == 1 || object.state_id == 3"
                  class="float-end mr-2"
                  color="error"
                  @click="toMistakes">
                Перевести в "Есть замечания"
              </v-btn>
              <v-btn
                  v-if="object.state_id == 2" color="primary"
                  class="float-end mr-2"
                  @click="toFixed">
                Перевести в "Исправлено"
              </v-btn>
              <v-btn
                  v-if="object.state_id == 1 || object.state_id == 3"
                  class="float-end mr-2"
                  color="success"
                  @click="toVerified">
                Перевести в "Проверено"
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :md="show_comment_block ? 8 : 12">
      <v-tabs v-model="tab">
        <v-tab
            v-for="component in components"
            :key="component.id"
            @click="getParameters(component)"
        >
          {{ component.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
          v-model="tab"
          class="pa-1"
          :touchless="true"
          style="margin-bottom: 72px"
      >
        <v-tab-item
            v-for="component in components"
            :key="component.id"
        >

          <object-parameters-form-display
              v-if="component.is_form"
              :component="component"
              @update-parameters-row="(row) => getParametersRow(row, component.id)">
          </object-parameters-form-display>

          <object-parameters-list
              v-if="!component.is_form"
              :component="component"
              :rows="parametersRow"
              @update-parameters-row="(row) => getParametersRow(row, component.id)"
              @delete-parameters-row="(row) => removeRow(row, component)"
          />

        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <v-col :class="{'d-none': !show_comment_block}" :md="4">
      <v-card max-height="550">
        <v-toolbar color="primary">
          <v-toolbar-title style="color:white;">Комментарии</v-toolbar-title>
        </v-toolbar>
        <div style="height: 300px;overflow:scroll;">
          <v-list-item v-for="item in comments" :key="item.id">
            <v-list-item-content>
              <v-list-item-title v-html="item.getTitle()"></v-list-item-title>
              <v-list-item-subtitle class="wrap-text" v-html="item.comment"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-textarea
            style="margin-top: 5px; padding: 10px"
            hide-details
            rows="3"
            auto-grow
            outlined
            label="Комментарий"
            v-model="commentPayload.comment"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createComment">
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <object-parameters-form
        :params-values="params_values"
        :dialog="dialog"
        :edit-mode="editMode"
        :current-row-id="currentRowId"
        @toggle-dialog="(value) => this.dialog = value"
        @toggle-edit-mode="(value) => this.editMode = value"
        @create-parameters="createParameters()"
    />

    <v-btn
        color="primary"
        dark
        fixed
        bottom
        right
        fab
        @click="show_comment_block = !show_comment_block"
    >
      <v-icon>{{ show_comment_block ? 'mdi-comment-remove' : 'mdi-comment' }}</v-icon>
    </v-btn>

    <v-dialog
        v-model="updateDialog"
        max-width="800px"
    >
      <update-object-form
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import 'viewerjs/dist/viewer.css'
import ObjectParametersList from "@/components/InventoryObject/ObjectParametersList";
import ObjectParametersForm from "@/components/InventoryObject/ObjectParametersForm";
import UpdateObjectForm from "@/components/InventoryObject/UpdateObjectForm";
import ObjectParametersFormDisplay from "@/components/InventoryObject/ObjectParametersFormDisplay";
import InventoryObjectsService from "@/services/InventoryObjectsService";
import ObjectState from "@/components/InventoryObject/ObjectState.vue";

export default {
  name: "InventoryObjectPage",
  components: {ObjectState, UpdateObjectForm, ObjectParametersForm, ObjectParametersList, ObjectParametersFormDisplay},
  computed: {
    ...mapGetters({
      object: 'inventory_objects/getInventoryObject',
      components: 'components/getComponents',
      parameters: 'parameters/getParameters',
      objectParams: 'inventory_objects_params/getObjectParameters',
      parametersRow: 'inventory_objects_params/getParametersRow',
      objectTypeName: 'object_types/getObjectTypeName'
    }),
  },
  data() {
    return {
      show_comment_block: false,
      params_values: [],
      current_row_id: null,
      tab: null,
      dialog: false,
      showDialog: false,
      editMode: false,
      currentRowId: null,
      updateDialog: false,
      comments: [],
      commentPayload: {
        comment: null
      },
      isGenerateSuccess: false,
      isGenerateError: false,
    }
  },
  mounted() {
    this.$store.dispatch('object_types/getObjectTypes');
    this.getObject();
    this.getComments();
  },
  methods: {
    getObject() {
      this.$store.dispatch('inventory_objects/getInventoryObject', this.$route.params.id)
          .then(() => {
            this.$store.dispatch('components/getComponents', this.object.object_type_id)
                .then(() => {
                  const [first_component] = this.components;
                  this.$store.dispatch('inventory_objects_params/getParameters', {
                    object_id: this.object.id,
                    component_id: first_component.id
                  });

                  this.$store.commit('components/SET_ACTIVE_COMPONENT_ID', first_component.id);
                  this.$store.commit('components/SET_ACTIVE_COMPONENT_FORM_STATE', first_component.is_form);

                  this.$store.dispatch('parameters/getComponentParameters', {
                    object_type_id: this.object.object_type_id,
                    component_id: first_component.id
                  })
                      .then(() => this.createParameters());
                })
          });
    },
    getComments() {
      InventoryObjectsService.getComments(this.$route.params.id).then((results) => {
        this.comments = results;
      });
    },
    createComment() {
      InventoryObjectsService.createComment(this.$route.params.id, this.commentPayload).then(() => {
        this.getComments();
        this.commentPayload.comment = null;
      })
    },
    getParameters(component) {
      this.$store.commit('components/SET_ACTIVE_COMPONENT_ID', component.id);
      this.$store.commit('components/SET_ACTIVE_COMPONENT_FORM_STATE', component.is_form);
      this.$store.dispatch('inventory_objects_params/getParameters', {
        object_id: this.object.id,
        component_id: component.id
      });

      this.$store.dispatch('parameters/getComponentParameters', {
        object_type_id: this.object.object_type_id,
        component_id: component.id
      })
          .then(() => this.createParameters())
    },
    createParameters() {
      this.params_values = this.parameters.map(parameter => {
        return {
          id: parameter.id,
          name: parameter.name,
          type: parameter.type,
          values: parameter.values,
          manual_mode: 0,
          value: null
        }
      })
    },
    removeRow(row, component) {
      const [first_parameter] = row
      this.$store.dispatch('inventory_objects_params/deleteParametersRow', {
        'object_id': this.object.id,
        'component_id': component.id,
        'row_id': first_parameter.row_id
      }).then(() => {
        this.$store.dispatch('inventory_objects_params/getParameters', {
          object_id: this.object.id,
          component_id: component.id
        });

        this.$store.commit('components/SET_ACTIVE_COMPONENT_ID', component.id);
        this.$store.commit('components/SET_ACTIVE_COMPONENT_FORM_STATE', component.is_form);

        this.$store.dispatch('parameters/getComponentParameters', {
          object_type_id: this.object.object_type_id,
          component_id: component.id
        })
            .then(() => this.createParameters());
      });
    },
    getParametersRow(row, component_id) {
      const [first_parameter] = row
      this.$store.dispatch('inventory_objects_params/getParametersRow', {
        object_id: this.object.id,
        component_id: component_id,
        row_id: first_parameter.row_id
      })
          .then(() => {
            this.currentRowId = first_parameter.row_id;
            this.params_values.forEach(parameter => {
              const rowParameter = this.parametersRow.find(param => param.parameter_id === parameter.id);
              if (rowParameter === undefined) {
                return;
              }
              if (parameter.type === 'FILE') {
                parameter.value = ''
              } else if (parameter.type === 'DIRECTORY') {
                if (rowParameter.manual_mode === 0) parameter.value = parseInt(rowParameter.raw_value)
                else if (rowParameter.manual_mode === 1) parameter.value = rowParameter.raw_value
              } else if (parameter.type === 'DIRECTORY_PHOTO') {
                parameter.value = parseInt(rowParameter.raw_value)
              } else if (parameter.type === 'TC_CALCULATOR') {
                parameter.value = rowParameter.raw_value ?
                    rowParameter.raw_value.split(',').map(item => parseInt(item)) : []
              } else if (parameter.type === 'MULTI_PHOTO') {
                parameter.raw_value = rowParameter.raw_value;
                parameter.value = rowParameter.value;
                parameter.deleted_values = [];
              } else if (parameter.type === 'PHOTO_DEFECTS') {
                parameter.value = rowParameter.value;
                parameter.raw_value = rowParameter.raw_value;
                parameter.deleted_values = [];
              } else parameter.value = rowParameter.value;
              parameter.manual_mode = rowParameter.manual_mode;
            })

            this.dialog = true;
            this.editMode = true;
          })
    },
    toMistakes() {
      this.$store.dispatch('inventory_objects/stateToMistakes', this.$route.params.id).then(() => {
        this.getObject();
      });
    },
    toFixed() {
      this.$store.dispatch('inventory_objects/stateToFixed', this.$route.params.id).then(() => {
        this.getObject();
      });
    },
    toVerified() {
      this.$store.dispatch('inventory_objects/stateToVerified', this.$route.params.id).then(() => {
        this.getObject();
      });
    },
    generateProtocol() {
      let type = 0;

      if (this.object.object_type_id == 7) {
        type = 1;
      }
      if (this.object.object_type_id == 8) {
        type = 2;
      }

      this.$store.dispatch('protocols/generateProtocol', {
        object_id: this.$route.params.id,
        is_excel: type
      }).then(() => {
        this.isGenerateSuccess = true;
      }).catch(() => {
        this.isGenerateError = true;
      });
    }
  }
}
</script>

<style scoped>
.object-data-name {
  font-size: 14px;
  font-weight: 400;
  color: #262f3d;
}

.object-data {
  font-size: 16px;
  font-weight: 500;
  color: #262f3d;
}

.wrap-text {
  white-space: normal;
}

.custom-alert {
  position: fixed;
  top: 60px; /* Позиция сверху */
  right: 20px;
  z-index: 9999; /* Высокий z-index для отображения поверх всего */
}
</style>
