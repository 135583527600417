import InventoryObjectParamsService from "@/services/InventoryObjectParamsService";

export const namespaced = true;

export const state = {
    parameters: [],
    parametersRow: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_PARAMETERS(state, parameters) {
        state.parameters = parameters;
    },
    SET_PARAMETER_ROW(state, parameter) {
        state.parametersRow = parameter;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getParameters({commit}, payload) {
        commit("SET_LOADING", true);
        InventoryObjectParamsService.getInventoryObjectParams(payload)
            .then(parameters => commit("SET_PARAMETERS", parameters))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getParametersRow({commit}, {object_id, component_id, row_id}) {
        return new Promise(resolve => {
            InventoryObjectParamsService.getParametersRow({object_id, component_id, row_id})
                .then(parameters => {
                    commit("SET_PARAMETER_ROW", parameters);
                    resolve();
                })
                .catch(error => commit("SET_ERROR", error))
        })
    },
    saveParameters({commit}, payload) {
        return new Promise((resolve, reject) => {
            InventoryObjectParamsService.saveParameters(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
        })
    },
    updateParametersRow({commit}, {object_id, component_id, row_id, payload}) {
        return new Promise((resolve, reject) => {
            InventoryObjectParamsService.updateParametersRow({object_id, component_id, row_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
        })
    },
    deleteParametersRow({commit}, {object_id, component_id, row_id}) {
        return new Promise((resolve, reject) => {
            InventoryObjectParamsService.deleteParametersRow({object_id, component_id, row_id})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
        })
    }
}

export const getters = {
    getObjectParameters: state => {
        return state.parameters;
    },
    getRowByIndex: state => row => {
        return state.parameters[row]
    },
    getValueFromRow: state => (row_id, param_id) => {
        const row = state.parameters[row_id];

        if (row === undefined) {
            return null;
        }
        return row.filter(function (item) {
            return item.parameter_id === param_id
        })[0];
    },
    getParametersRow: state => {
        return state.parametersRow
    },
    getRowsCount: state => {
        return state.parameters.length
    }
}
