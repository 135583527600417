<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Наименование</th>
            <th class="text-left">Категория</th>
            <th class="text-left">Наличие</th>
            <th class="text-left">Носитель</th>
            <th class="text-left">Статус загрузки</th>
            <th class="text-left">Примечание</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in values"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ categoryName(item.category) }}</td>
            <td>{{ InitialDataValue.getAvailabilities().find(i => i.value === item.availability).text }}</td>
            <td>{{ InitialDataValue.getCarriers().find(i => i.value === item.carrier).text }}</td>
            <td>{{ InitialDataValue.getUploadState().find(i => i.value === item.uploaded).text }}</td>
            <td>{{ item.remark ? item.remark : '-' }}</td>
            <td>
              <v-icon
                  @click="updateObjectValue(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-text-box-edit
              </v-icon>
              <v-icon
                  @click="deleteObjectValue(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
        v-model="updateDialog"
        max-width="600px"
    >
      <object-value-update-form
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import InitialDataValue from "@/models/InitialDataValue";
import ObjectValueUpdateForm from "@/components/InitialData/ObjectValueUpdateForm";

export default {
  name: "ObjectValuesList",
  components: {ObjectValueUpdateForm},
  data() {
    return {
      updateDialog: false
    }
  },
  computed: {
    ...mapGetters({
      values: 'initialDataObjects/getObjectValues',
      object: 'initialDataObjects/getObject',
      categoryName: 'initialDataCategories/getCategoryName'
    }),
    InitialDataValue: () => InitialDataValue
  },
  methods: {
    updateObjectValue(id) {
      this.$store.dispatch('initialDataObjects/getInitialDataObjetValue', {
        object_id: this.object.id,
        value_id: id
      }).then(() => this.updateDialog = true);
    },
    deleteObjectValue(id) {
      this.$store.dispatch('initialDataObjects/deleteInitialObjectValue', {
        object_id: this.object.id,
        value_id: id
      }).then(() => this.$store.dispatch('initialDataObjects/getInitialDataObjectValues', this.object.id));
    }
  }
}
</script>

<style scoped>

</style>