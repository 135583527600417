<template>
  <v-card>
    <v-card-title>Обновить объект</v-card-title>

    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Наименование"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.address"
            :rules="notNullRule"
            label="Адрес"
            required
        ></v-text-field>

        <v-btn
            color="primary"
            block
            @click="submitForm()"
        >Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ObjectUpdateForm",
  props: ['dialogState'],
  data() {
    return {
      valid: false,
      notNullRule: [
          v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null,
        address: null
      }
    }
  },
  computed: {
    ...mapGetters({
      object: 'initialDataObjects/getObject'
    })
  },
  watch: {
    dialogState: function (state) {
      if (state) this.setFields();
    }
  },
  mounted() {
    this.setFields();
  },
  methods: {
    setFields() {
      this.payload = {
        name: this.object.name,
        address: this.object.address
      }
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('initialDataObjects/updateInitialDataObject', {
          id: this.object.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('initialDataObjects/getInitialDataObjects');
              this.$emit('success-request');

              this.payload = {
                name: null,
                address: null
              }

              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>

</style>