<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Имя</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in constructionTypes"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <v-icon
                  @click="updateConstructionType(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-text-box-edit
              </v-icon>
              <v-icon
                  @click="deleteConstructionType(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
        v-model="updateDialog"
        max-width="600px"
    >
      <construction-type-update-form
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import ConstructionTypeUpdateForm from "@/components/Construction/ConstructionTypeUpdateForm";

export default {
  name: "ConstructionTypesList",
  components: {ConstructionTypeUpdateForm},
  data() {
    return {
      updateDialog: false
    }
  },
  computed: {
    ...mapGetters({
      constructionTypes: 'constructionTypes/getConstructionTypes'
    })
  },
  methods: {
    updateConstructionType(id) {
      this.$store.dispatch('constructionTypes/getConstructionType', id)
          .then(() => this.updateDialog = true);
    },
    deleteConstructionType(id) {
      this.$store.dispatch('constructionTypes/deleteConstructionType', id)
          .then(() => {
            this.$store.dispatch('constructionTypes/getConstructionTypes');
          })
    }
  }
}
</script>

<style scoped>

</style>