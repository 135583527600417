import RoleService from "@/services/RoleService";

export const namespaced = true;

export const state = {
    roles: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_ROLES(state, roles) {
        state.roles = roles;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    }
}

export const actions = {
    getRoles({commit}) {
        commit("SET_LOADING", true);
        RoleService.fetchRoles()
            .then(roles => commit("SET_ROLES", roles))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
}

export const getters = {
    getRoles: state => {
        return state.roles;
    },
}
