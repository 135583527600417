<template>
  <v-row>
    <v-col md="12">
      <objects-list />
    </v-col>
  </v-row>
</template>

<script>
import ObjectsList from "@/components/InventoryObject/ObjectsList";

export default {
  name: "InventoryObjectsListPage",
  components: {ObjectsList}
}
</script>

<style scoped>

</style>