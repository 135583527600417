import DirectoriesService from "@/services/DirectoriesService";

export const namespaced = true;

export const state = {
    directories: [],
    directory: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_DIRECTORIES(state, directories) {
        state.directories = directories;
    },
    SET_DIRECTORY(state, directory) {
        state.directory = directory;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getDirectories({commit}) {
        commit("SET_LOADING", true);
        DirectoriesService.getDirectories()
            .then(directories => commit("SET_DIRECTORIES", directories))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getDirectory({commit}, id) {
        DirectoriesService.getDirectory(id)
            .then(directory => commit("SET_DIRECTORY", directory))
            .catch(error => commit("SET_ERROR", error));
    },
    createDirectory({commit}, payload) {
        return new Promise((resolve, reject) => {
            DirectoriesService.createDirectory(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    updateDirectory({commit}, {directory_id, payload}) {
        return new Promise((resolve, reject) => {
            DirectoriesService.updateDirectory({directory_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    deleteDirectory({commit}, directory_id) {
        return new Promise((resolve, reject) => {
            DirectoriesService.deleteDirectory(directory_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    }
}

export const getters = {
    getDirectories: state => {
        return state.directories;
    },
    getDirectoryById: state => id => {
        return state.directories.find(directory => directory.id === parseInt(id));
    },
    getDirectory: state => {
        return state.directory;
    }
}