<template>
  <v-dialog
    v-model="dialogPropModel"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        style="right: 80px"
        color="primary"
        dark
        fixed
        bottom
        right
        fab
        v-bind="attrs"
        v-on="on"
        v-show="!componentFormCheck || (rowsCount === 0 && componentFormCheck)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>Форма параметров объекта</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="editModePropModel"
            dark
            text
            @click="updateParametersRow()"
          >Изменить
          </v-btn>
          <v-btn
            v-else
            dark
            text
            @click="submitForm()"
          >Сохранить
          </v-btn>
        </v-toolbar-items>

      </v-toolbar>
      <v-card-text>
        <v-form class="mt-4">
          <div v-for="parameter in paramsValues"
               :key="parameter.id">

            <v-text-field
              v-if="parameter.type === 'INPUT'"
              v-model="parameter.value"
              :label="parameter.name"
            ></v-text-field>

            <v-item-group v-else-if="parameter.type === 'DIRECTORY'">
              <v-item v-if="parameter.manual_mode === 0">
                <v-select
                  v-model="parameter.value"
                  :label="parameter.name"
                  :items="parameter.values"
                  item-text="values"
                  item-value="id"
                  hide-details
                ></v-select>
              </v-item>
              <v-item v-if="parameter.manual_mode === 1">
                <v-text-field
                  v-model="parameter.value"
                  :label="parameter.name"
                  hide-details
                ></v-text-field>
              </v-item>
              <v-item>
                <v-checkbox
                  v-model="parameter.manual_mode"
                  label="Ручной ввод"
                  :true-value="1"
                  :false-value="0"
                  class="mt-1"
                  hide-details
                ></v-checkbox>
              </v-item>
            </v-item-group>

            <v-select
              v-else-if="parameter.type === 'DIRECTORY_PHOTO'"
              :label="parameter.name"
              :items="parameter.values"
              item-value="id"
              v-model="parameter.value"
            >
              <template v-slot:selection="{ item }">
                <img
                  alt="Изображение словаря"
                  :src="item.img_url"
                >
              </template>
              <template v-slot:item="{ item }">
                <img
                  alt="Изображение словаря"
                  :src="item.img_url"
                >
              </template>
            </v-select>

            <multi-photo
              v-else-if="parameter.type === 'MULTI_PHOTO'"
              :parameter-name="parameter.name"
              :parameter-value="parameter.value"
              :parameter-raw-value="parameter.raw_value"
              :edit-mode="editMode"
              @update:modelValue="value => parameter.value = value"
              @update:deletedValues="value => parameter.deleted_values.push(value)"
            />

            <photo-defects
              v-else-if="parameter.type === 'PHOTO_DEFECTS'"
              :parameter-values="parameter.values"
              :parameter-value="parameter.value"
              :parameter-raw-value="parameter.raw_value"
              :form-label="parameter.name"
              :edit-mode="editMode"
              @update:defectsLit="value => parameter.value = value"
              @update:deletedValues="value => parameter.deleted_values.push(value)"/>

            <v-file-input
              v-if="parameter.type === 'FILE'"
              v-model="parameter.value"
              :label="parameter.name"
            ></v-file-input>

            <v-select
              v-if="parameter.type === 'TC_CALCULATOR'"
              v-model="parameter.value"
              :label="parameter.name"
              :items="parameter.values"
              item-value="id"
              item-text="name"
              multiple
            ></v-select>

          </div>
          <v-row v-if="editModePropModel">
            <v-col md="6">
              <v-btn
                color="primary"
                block
                @click="updateParametersRow()"
                class="mt-4"
              >Изменить
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn
                color="secondary"
                block
                @click="() => {
                     closeDialog()
                  }"
                class="mt-4"
              >Отменить
              </v-btn>
            </v-col>
          </v-row>
          <v-btn
            v-else
            color="primary"
            block
            @click="submitForm()"
            class="mt-4"
          >Сохранить
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import MultiPhoto from "@/components/InventoryObject/ParameterInputs/MultiPhoto.vue";
import PhotoDefects from "@/components/InventoryObject/ParameterInputs/PhotoDefects.vue";
import {emitter} from "@/utils/emmiter";

export default {
  name: "ObjectParametersForm",
  components: {PhotoDefects, MultiPhoto},
  props: {
    paramsValues: {
      type: Array,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    currentRowId: {
      default: null
    }
  },
  computed: {
    ...mapGetters({
      object: 'inventory_objects/getInventoryObject',
      component_id: 'components/getActiveComponentId',
      componentFormCheck: 'components/getActiveComponentFormState',
      rowsCount: 'inventory_objects_params/getRowsCount'
    }),
    dialogPropModel: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit('toggle-dialog', value);
      }
    },
    editModePropModel: {
      get() {
        return this.editMode;
      },
      set(value) {
        this.$emit('toggle-edit-mode', value);
      }
    }
  },
  methods: {
    submitForm() {
      const payload = {
        object_id: this.object.id,
        component_id: this.component_id,
        params: this.paramsValues.map(parameter => {
          let parameterValue;

          if (parameter.type === 'TC_CALCULATOR') parameterValue = parameter.value ? parameter.value.join(',') : null
          else if (parameter.type === 'PHOTO_DEFECTS') {
            parameterValue = parameter.value ? parameter.value.map(item => item.photo) : null
          } else parameterValue = parameter.value

          const parameterSaveObject = {
            id: parameter.id,
            type: parameter.type,
            value: parameterValue,
            manual_mode: parameter.manual_mode || 0
          }

          if (parameter.type === 'PHOTO_DEFECTS') {
            parameterSaveObject.second_value = parameter.value ?
              parameter.value.map(item => `${item.defect}|${item.manualMode}`) : null
          }

          return parameterSaveObject;
        })
      }
      this.$store.dispatch('inventory_objects_params/saveParameters', payload)
        .then(() => {
          this.$store.dispatch('inventory_objects_params/getParameters', {
            object_id: this.object.id,
            component_id: this.component_id
          });
          this.$emit('toggle-dialog', false);
          emitter.emit('parameters-form-submit');
        })
      this.$emit('create-parameters');
    },
    updateParametersRow() {
      const payload = {
        object_id: this.object.id,
        component_id: this.component_id,
        row_id: this.currentRowId,
        payload: this.paramsValues.map(parameter => {
          let parameterValue;
          if (parameter.type === 'MULTI_PHOTO') {
            parameterValue = parameter.value ?
              parameter.value.filter(item => typeof item !== 'string') : null
          } else if (parameter.type === 'TC_CALCULATOR') {
            parameterValue = parameter.value ?
              parameter.value.join(',') : null
          } else if (parameter.type === 'PHOTO_DEFECTS') {
            parameterValue = parameter.value ?
              parameter.value
                .filter(item => !item.old)
                .map(item => item.photo)
              : null
          } else parameterValue = parameter.value;

          const parameterSaveObject = {
            id: parameter.id,
            type: parameter.type,
            value: parameterValue,
            manual_mode: parameter.manual_mode || 0
          }

          if (parameter.type === 'MULTI_PHOTO') parameterSaveObject.deleted_values = parameter.deleted_values ?
            parameter.deleted_values.join(',') : null

          if (parameter.type === 'PHOTO_DEFECTS') {
            parameterSaveObject.deleted_values = null;

            if ('deleted_values' in parameter) {
              parameterSaveObject.deleted_values = parameter.deleted_values.join(';');
            }

            parameterSaveObject.second_value = parameter.value ?
              parameter.value
                .filter(item => !item.old)
                .map(item => `${item.defect}|${item.manualMode}`) : null
          }

          return parameterSaveObject;
        })
      };
      this.$store.dispatch('inventory_objects_params/updateParametersRow', payload)
        .then(() => {
          this.$store.dispatch('inventory_objects_params/getParameters', {
            object_id: this.object.id,
            component_id: this.component_id
          });
          emitter.emit('parameters-form-submit');
          this.closeDialog();
        })
    },
    closeDialog() {
      this.$emit('toggle-dialog', false);
      this.$emit('toggle-edit-mode', false);
      this.$emit('create-parameters');
    }
  }
}
</script>

<style scoped>
</style>
