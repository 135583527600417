<template>
  <v-card
      elevation="2"
      class="mt-4"
  >
    <v-card-text v-if="objectParams.length">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th>Наименование</th>
            <th>Значение</th>
            <th>Редактирование</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in componentParams" :key="index">
            <td>
              {{ item.name }}
            </td>
            <td>
              <div v-if="getValueRow(0, item.id) != null">
                <div v-if="getValueRow(0, item.id).parameter_type === 'FILE'">
                  <a v-if="getFileType(getValueRow(0, item.id).value) === 'pdf'"
                     :href="getValueRow(0, item.id).value"
                     target="_blank"
                     class="show-image">
                    Открыть файл
                  </a>
                  <span v-else
                        @click="showImage(getValueRow(0, item.id).value)"
                        class="show-image">Показать фото</span>
                </div>

                <div v-if="getValueRow(0, item.id).parameter_type === 'PHOTO_DEFECTS'">
                  <object-photo-defects :row="getValueRow(0, item.id)"/>
                </div>

                <div v-else>{{ getValueRow(0, item.id).value }}</div>
              </div>
            </td>
            <td>
              <v-icon
                  class="cursor-pointer"
                  @click="$emit('update-parameters-row', objectParams[0])"
              >mdi-text-box-edit</v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card-text>
    <v-card-text
        v-else
        class="text-center"
    >
      Параметры не найдены
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import VueViewer from 'v-viewer'
import Vue from 'vue'
import ObjectPhotoDefects from "@/components/InventoryObject/ObjectPhotoDefects.vue";

Vue.use(VueViewer);

export default {
  name: "ObjectParametersFormDisplay",
  components: {ObjectPhotoDefects},
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      parameterName: 'parameters/getParameterName',
      objectParams: 'inventory_objects_params/getObjectParameters',
      componentParams: 'parameters/getParameters',
      getValueRow: 'inventory_objects_params/getValueFromRow'
    })
  },
  methods: {
    getFileType(url) {
      if (url == null) {
        return '';
      }
      return url.split('.').pop()
    },
    showImage(image) {
      this.$viewerApi({
        images: [image],
        options: {
          navbar: false,
          title: false,
          toolbar: false
        }
      })
    }
  }
}
</script>

<style scoped>
.show-image {
  color: #1178bd;
  font-weight: 600;
  cursor: pointer;
}
</style>
