import ParametersService from "@/services/ParametersService";

export const namespaced = true;

export const state = {
    parameters: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_PARAMETERS(state, parameters) {
        state.parameters = parameters;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getComponentParameters({commit}, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ParametersService.getParameters(payload)
                .then(parameters => {
                    commit("SET_PARAMETERS", parameters);
                    resolve()
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject()
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    createParameter({commit}, payload) {
        return new Promise((resolve, reject) => {
            ParametersService.createParameter(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error)
                    reject();
                })
        })
    },
    updateParameter({commit, rootState}, {parameterId, payload}) {
        return new Promise((resolve, reject) => {
            ParametersService.updateParameter(
                rootState.object_types.active_object_type_id,
                rootState.components.active_component_id,
                parameterId,
                payload
            )
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error)
                    reject();
                })
        })
    },
    activateParameter({commit, rootState}, parameterId) {
        return new Promise((resolve, reject) => {
            ParametersService.activateParameter(
                rootState.object_types.active_object_type_id,
                rootState.components.active_component_id,
                parameterId
            )
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error)
                    reject();
                })
        })
    },
    deactivateParameter({commit, rootState}, parameterId) {
        return new Promise((resolve, reject) => {
            ParametersService.deactivateParameter(
                rootState.object_types.active_object_type_id,
                rootState.components.active_component_id,
                parameterId
            )
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error)
                    reject();
                })
        })
    }
}

export const getters = {
    getParameters: state => {
        return state.parameters;
    },
    getParameterName: state => id => {
        return state.parameters.find(parameter => parameter.id === id)?.name;
    }
}
