<template>
  <v-card>
    <v-card-title>Обновит тип конструкции</v-card-title>

    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Наименование"
            required
        ></v-text-field>

        <v-btn
            color="primary"
            block
            @click="submitForm()"
        >Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConstructionTypeUpdateForm",
  props: ['dialogState'],
  data() {
    return {
      valid: false,
      notNullRule: [
          v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null
      }
    }
  },
  computed: {
    ...mapGetters({
      constructionType: 'constructionTypes/getConstructionType'
    })
  },
  watch: {
    dialogState: function (state) {
      if (state) this.setFields();
    }
  },
  mounted() {
    this.setFields();
  },
  methods: {
    setFields() {
      this.payload.name = this.constructionType.name;
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('constructionTypes/updateConstructionType', {
          id: this.constructionType.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('constructionTypes/getConstructionTypes');
              this.$emit("success-request");

              this.payload = {
                name: null
              }

              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>

</style>