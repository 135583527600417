import * as API from './API';

export default {
    createTechState(objcetId, payload) {
        return API.apiClient.post(`/api/catalogs/tcc/eval-objects/${objcetId}/tech-states`, payload);
    },
    updateTechState(objectId, techStateId, payload) {
        return API.apiClient.patch(`/api/catalogs/tcc/eval-objects/${objectId}/tech-states/${techStateId}`, payload)
    }
}
