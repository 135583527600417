import { render, staticRenderFns } from "./DevicesForm.vue?vue&type=template&id=0d5964a5&scoped=true&"
import script from "./DevicesForm.vue?vue&type=script&lang=js&"
export * from "./DevicesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5964a5",
  null
  
)

export default component.exports