<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">ФИО Инженера</th>
          <th class="text-left">Тип объекта</th>
          <th class="text-left">Количество объектов</th>
        </tr>
      </thead>
      <tbody>
        <tr
            v-for="item in items"
            :key="item.id"
        >
          <td>{{ item.user_name }}</td>
          <td>{{ item.object_type_name }}</td>
          <td>{{ item.completed_quantity }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "EngineerResultsList",
  props: [
    'items'
  ],
}
</script>

<style scoped>

</style>
