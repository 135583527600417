<template>
  <v-col md="12">
    <v-card elevation="2">
      <v-card-text>
        <protocols-list />
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <create-protocol-form @close-dialog="dialog = false" />
    </v-dialog>

  </v-col>
</template>

<script>
import ProtocolsList from "@/components/Protocol/ProtocolsList";
import CreateProtocolForm from "@/components/Protocol/CreateProtocolForm";
export default {
  name: "ProtocolsListPage",
  components: {CreateProtocolForm, ProtocolsList},
  data() {
    return {
      dialog: false
    }
  },
  mounted() {
    this.$store.dispatch('protocols/getProtocols');
    this.$store.dispatch('inventory_objects/getInventoryObjects');
  }
}
</script>

<style scoped>

</style>