<template>
  <div>
    <h3 v-if="directory"
        class="mb-5 mt-2 text-center">
      Справочник {{directory.name}}
    </h3>
    <directory-item-form />
    <directory-items-list />
  </div>
</template>

<script>
import DirectoryItemForm from "@/components/Drectory/DirectoryItemForm";
import DirectoryItemsList from "@/components/Drectory/DirectoryItemsList";
import {mapGetters} from "vuex";
export default {
  name: "DirectoryPage",
  components: {DirectoryItemsList, DirectoryItemForm},
  computed: {
    ...mapGetters({
      directory: 'directories/getDirectory'
    })
  },
  mounted() {
    this.$store.dispatch('directories/getDirectory', this.$route.params.id);
    this.$store.dispatch('directory_items/getDirectoryItems', this.$route.params.id);
  }
}
</script>

<style scoped>

</style>