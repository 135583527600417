import * as API from '@/services/API'
import Parameter from "@/models/InventoryObject/Parameter";

export default {
    getParameters(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/object-types/${payload.object_type_id}/components/${payload.component_id}/parameters`)
                .then(response => {
                    resolve(response.data.data.map(parameter => new Parameter(parameter)))
                })
                .catch(error => reject(error));
        })
    },
    createParameter(payload) {
        return API.apiClient.post(`/api/catalogs/object-types/${payload.object_type_id}
        /components/${payload.component_id}/parameters`, payload.params);
    },
    updateParameter(object_type_id, component_id, parameter_id, payload) {
        return API.apiClient.patch(`/api/catalogs/object-types/${object_type_id}
        /components/${component_id}/parameters/${parameter_id}`, payload);
    },
    activateParameter(object_type_id, component_id, parameter_id) {
        return API.apiClient.post(`/api/catalogs/object-types/${object_type_id}
        /components/${component_id}/parameters/${parameter_id}/activate`);
    },
    deactivateParameter(object_type_id, component_id, parameter_id) {
        return API.apiClient.post(`/api/catalogs/object-types/${object_type_id}
        /components/${component_id}/parameters/${parameter_id}/deactivate`);
    }
}
