import EngineersService from "@/services/EngineersService";

export const namespaced = true;

export const state = {
    engineers: [],
    engineer: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_ENGINEERS(state, engineers) {
        state.engineers = engineers;
    },
    SET_ENGINEER(state, engineer) {
        state.engineer = engineer;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getEngineers({commit}) {
        commit("SET_LOADING", true);
        EngineersService.getEngineers()
            .then(engineers => commit("SET_ENGINEERS", engineers))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getEngineer({commit}, engineer_id) {
        return new Promise((resolve, reject) => {
            EngineersService.getEngineer(engineer_id)
                .then(engineer => {
                    commit("SET_ENGINEER", engineer);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createEngineer({commit}, payload) {
        return new Promise((resolve, reject) => {
            EngineersService.createEngineer(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateEngineer({commit}, {engineer_id, payload}) {
        return new Promise((resolve, reject) => {
            EngineersService.updateEngineer({engineer_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteEngineer({commit}, engineer_id) {
        return new Promise((resolve, reject) => {
            EngineersService.deleteEngineer(engineer_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getEngineers: state => {
        return state.engineers;
    },
    getEngineer: state => {
        return state.engineer;
    }
}
