<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ userName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <div class="logout-btn"
               @click="logout">Выйти
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider/>

    <v-list dense nav>
      <div v-for="item in items" :key="item.title">
        <v-list-item v-if="!item.group && authUser.isHasRole(item.available_for)" link :to="item.route">

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

        <v-list-group v-else-if="item.group && authUser.isHasRole(item.available_for)" :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <v-list-item v-for="page in item.pages" :key="page.route" link :to="page.route">
            <v-list-group no-action sub-group v-if="page.group" :prepend-icon="page.icon">
              <template v-slot:activator>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </template>
              <v-list-item v-for="page_2 in page.pages" :key="page_2.route" link :to="page_2.route">
                <v-list-item-title>{{ page_2.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item>

        </v-list-group>
      </div>
    </v-list>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TheSidebar",
  data() {
    return {
      items: [
        {title: 'Главная', icon: 'mdi-view-dashboard', route: '/', group: false, available_for: null},
        {title: 'Объекты', icon: 'mdi-office-building-outline', route: '/inventory-objects', group: false, available_for: null},
        {title: 'Протоколы', icon: 'mdi-file-document-check', route: '/protocols', group: false, available_for: null},
        {title: 'Исходные данные', icon: 'mdi-database', route: '/initial-data-object', group: false, available_for: null},
        {title: 'Сводный отчет', icon: 'mdi-check-decagram-outline', route: '/reports/engineers-results-report', available_for: null},
        {title: 'Детальный отчет', icon: 'mdi-check-decagram-outline', route: '/reports/engineers-detail-results-report', available_for: null},
        {
          title: 'Настройки', icon: 'mdi-cogs', route: '', group: true, available_for: 'admin', pages: [
            {
              title: 'Инвентаризация', icon: 'mdi-cog', route: '', group: true, pages: [
                {title: 'Типы объектов', icon: 'mdi-dots-hexagon', route: '/construction-types'},
                {title: 'Справочники', icon: 'mdi-list-box', route: '/directories'},
                {title: 'Конфигурации', icon: 'mdi-office-building-cog-outline', route: '/object-types'},
                {title: 'Категории исх. данных', route: '/initial-data-categories'},
                {title: 'Инженеры', icon: 'mdi-human-male', route: '/engineers', group: false},
                {title: 'Устройства', icon: 'mdi-devices', route: '/devices', group: false},
                {title: 'Калькулятор ТС', icon: 'mdi-devices', route: '/evaluation-objects', group: false},
              ]
            },
            {
              title: 'Система', icon: 'mdi-cog', route: '', group: true, available_for: 'admin', pages: [
                {title: 'Пользователи', icon: 'mdi-account-group', route: '/users', group: false},
                {title: 'Проекты', icon: 'mdi-briefcase', route: '/projects', group: false},
              ]
            }
          ]
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/userName',
      authUser: 'auth/authUser',
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    }
  }
}
</script>

<style scoped>
.logout-btn {
  cursor: pointer;
}
</style>
