import DirectoryItemsService from "@/services/DirectoryItemsService";

export const namespaced = true;

export const state = {
    items: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_ITEMS(state, items) {
        state.items = items;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getDirectoryItems({commit}, directory_id) {
        commit("SET_LOADING", true);
        DirectoryItemsService.getDirectoryItems(directory_id)
            .then(items => commit("SET_ITEMS", items))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createDirectoryItem({commit}, {directory_id, payload}) {
        return new Promise((resolve, reject) => {
            DirectoryItemsService.createDirectoryItem({directory_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateDirectoryItem({commit}, {directory_id, item_id, payload}) {
        return new Promise((resolve, reject) => {
            DirectoryItemsService.updateDirectoryItem({directory_id, item_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteDirectoryItem({commit}, {directory_id, item_id}) {
        return new Promise((resolve, reject) => {
            DirectoryItemsService.deleteDirectoryItem({directory_id, item_id})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getDirectoryItems: state => {
        return state.items;
    },
    getDirectoryItem: state => id => {
        return state.items.find(item => item.id === id);
    }
}