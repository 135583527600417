import * as API from '@/services/API'
import InventoryObjectParameter from "@/models/InventoryObject/InventoryObjectParameter";

export default {
    getInventoryObjectParams({object_id, component_id}) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(
                `/api/inventory/objects/${object_id}/components/${component_id}/parameters`)
                .then(response => {
                    resolve(response.data.data.map(parameters_row => {
                        return parameters_row.map(parameter => new InventoryObjectParameter(parameter))
                    }))
                })
                .catch(error => reject(error));
        })
    },
    getParametersRow({object_id, component_id, row_id}) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`api/inventory/objects/${object_id}/components/${component_id}/parameters/${row_id}`)
                .then(response => {
                    resolve(response.data.data.map(parameter => {
                        return new InventoryObjectParameter(parameter)
                    }))
                })
                .catch(error => reject(error))
        })
    },
    saveParameters(payload) {
        return API.apiClient.post(
            `/api/inventory/objects/${payload.object_id}/components/${payload.component_id}/parameters`,
            payload.params, {
                'headers': {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },
    updateParametersRow({object_id, component_id, row_id, payload}) {
        return API.apiClient.post(
            `/api/inventory/objects/${object_id}/components/${component_id}/parameters/${row_id}`,
            payload, {
                'headers': {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    },
    deleteParametersRow({object_id, component_id, row_id}) {
        return API.apiClient.delete(
            `/api/inventory/objects/${object_id}/components/${component_id}/parameters/${row_id}`
        );
    }
}
