import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from "@/router";
import store from '@/store'
import '@/assets/css/style.css'
import vuetify from './plugins/vuetify'

Vue.use(VueRouter);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: router,
  vuetify,
  store
}).$mount('#app')
