import DevicesService from "@/services/DevicesService";

export const namespaced = true;

export const state = {
    devices: [],
    device: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_DEVICES(state, devices) {
        state.devices = devices;
    },
    SET_DEVICE(state, device) {
        state.device = device;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getDevices({commit}) {
        commit("SET_LOADING", true);
        DevicesService.getDevices()
            .then(devices => commit("SET_DEVICES", devices))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getDevice({commit}, device_id) {
        return new Promise((resolve, reject) => {
            DevicesService.getDevice(device_id)
                .then(device => {
                    commit("SET_DEVICE", device);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createDevice({commit}, payload) {
        return new Promise((resolve, reject) => {
            DevicesService.createDevice(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateDevice({commit}, {device_id, payload}) {
        return new Promise((resolve, reject) => {
            DevicesService.updateDevice({device_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteDevice({commit}, device_id) {
        return new Promise((resolve, reject) => {
            DevicesService.deleteDevice(device_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getDevices: state => {
        return state.devices;
    },
    getDevice: state => {
        return state.device;
    }
}