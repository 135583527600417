<template>
  <v-card>
    <v-card-title class="card-title--flex">
      Тех. состояния

      <v-btn
        color="primary"
        rounded
        @click="isModalActive = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Наименование</th>
            <th class="text-left">Значение</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="techState in techStatesList"
            :key="techState.id"
          >
            <td>{{techState.name}}</td>
            <td>{{techState.value.sign}}</td>
            <td>
              <v-icon @click="openUpdateModal(techState)"
                      class="cursor-pointer mr-2">mdi-text-box-edit</v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
      v-model="isModalActive"
      max-width="600px"
      @click:outside="clearFields"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>Добавить тех. состояние</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="payload.name"
              :rules="notNullRule"
              label="Наименование"
              required
            ></v-text-field>

            <v-select
              v-model="payload.value"
              :items="defectValues"
              :rules="notNullRule"
              label="Значение"
              item-text="name"
              item-value="id"
              required
            ></v-select>

            <v-text-field
              v-model="payload.description"
              :rules="notNullRule"
              label="Описание"
              required
            ></v-text-field>
            <v-btn
              @click="checkType"
              block
              color="primary"
            >Сохранить</v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "EvaluationObjectTechStatesList",
  props: {
    techStatesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isModalActive: false,
      valid: true,
      notNullRule: [
        v => v !== null || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null,
        value: null,
        description: null
      },
      isFormTypeCreate: true,
      currentTechStateId: 0
    }
  },
  computed: {
    ...mapState({
      defectValues: state => state.evaluationObjectDefects.defectValues
    })
  },
  methods: {
    openUpdateModal(techState) {
      this.currentTechStateId = techState.id;
      this.payload.name = techState.name;
      this.payload.value = techState.value;
      this.payload.description = techState.description;

      this.isFormTypeCreate = false;
      this.isModalActive = true;
    },
    checkType() {
      this.isFormTypeCreate ? this.createTechState() : this.updateTechState()
    },
    createTechState() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('evaluationObjectTechStates/createTechState', {
          objectId: this.$route.params.id,
          payload: this.payload
        })
          .then(() => {
            this.isModalActive = false;
            this.clearFields()
            this.$store.dispatch('evaluationObjects/getEvaluationObject', this.$route.params.id);
          })
      }
    },
    updateTechState() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('evaluationObjectTechStates/updateTechState', {
          objectId: this.$route.params.id,
          techStateId: this.currentTechStateId,
          payload: this.payload
        })
          .then(() => {
            this.isModalActive = false;
            this.clearFields()
            this.$store.dispatch('evaluationObjects/getEvaluationObject', this.$route.params.id);
          })
      }
    },
    clearFields() {
      this.isFormTypeCreate = true;
      this.$refs.form.resetValidation();
      this.currentTechStateId = 0;
      this.payload = {
        name: null,
        value: null,
        description: null
      }
    }
  }
}
</script>

<style scoped>
.card-title--flex {
  display: flex;
  justify-content: space-between;
}
</style>
