<template>
  <v-card class="mb-3">
    <v-card-title>Создать элемент справочник</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
        <div class="create-directory-item-form">
          <v-text-field
              v-if="directory($route.params.id).type === 1"
              v-model="value"
              :rules="notNullRule"
              label="Название элемента справочника"
              class="mr-3"
              required
          ></v-text-field>

          <v-text-field
              v-if="directory($route.params.id).type === 2"
              v-model="number"
              :rules="notNullRule"
              label="Порядковый номер"
              required
          ></v-text-field>

          <v-file-input
              v-if="directory($route.params.id).type === 2"
              v-model="img"
              :rules="notNullRule"
              label="Фото"
              required
          ></v-file-input>
          <v-btn
            color="primary"
            @click="submitForm"
          >Добавить</v-btn>
        </div>
      </v-form>

    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DirectoryItemForm",
  data() {
    return {
      value: null,
      number: null,
      img: null,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      directory: 'directories/getDirectoryById'
    })
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        const directoryId = this.$route.params.id;
        let payload;

        if (this.directory(directoryId).type === 1) {
          payload = {
            value: this.value
          }
        } else {
          payload = {
            number: this.number,
            img: this.img
          }
        }

        this.$store.dispatch('directory_items/createDirectoryItem', {
          directory_id: directoryId,
          payload: payload
        })
            .then(() => {
              this.$store.dispatch('directory_items/getDirectoryItems', directoryId);
              this.value = null;
              this.number = null;
              this.img = null;
              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>
.create-directory-item-form {
  display: flex;
  align-items: center;
}
</style>