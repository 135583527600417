import UsersService from "@/services/UsersService";

export const namespaced = true;

export const state = {
    users: [],
    activeUser: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_ACTIVE_USER(state, user) {
        state.activeUser = user;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    }
}

export const actions = {
    getUsers({commit}) {
        commit("SET_LOADING", true);
        UsersService.fetchUsers()
            .then(users => commit("SET_USERS", users))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getUser({commit}, userId) {
        return new Promise((resolve, reject) => {
            UsersService.getUser(userId)
                .then(user => {
                    commit("SET_ACTIVE_USER", user);
                    resolve()
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    createUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            UsersService.createUser(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateUser({commit}, {userId, payload}) {
        return new Promise((resolve, reject) => {
            UsersService.updateUser(userId, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    resetPassword({commit}, {userId, payload}) {
        return new Promise((resolve, reject) => {
            UsersService.resetPassword(userId, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    banUser({commit}, userId) {
        return new Promise((resolve, reject) => {
            UsersService.banUser(userId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    unbanUser({commit}, userId) {
        return new Promise((resolve, reject) => {
            UsersService.unbanUser(userId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getUsers: state => {
        return state.users;
    },
    getUser: state => {
        return state.activeUser;
    }
}
