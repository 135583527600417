<template>
  <v-card>
    <v-card-title>Сгенерировать протокол</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-select
            v-model="object_id"
            :rules="notNullRule"
            label="Объект инвентаризации"
            :items="objects.map(object => {
            return {value: object.id, text: object.name}
          })"
            required
        ></v-select>
        <v-btn
            block
            color="primary"
            class="mt-2"
            @click="submitForm"
        >
          Сгенерировать
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CreateProtocolForm",
  data() {
    return {
      valid: true,
      object_id: null,
      notNullRule: [
          v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  computed: {
    ...mapGetters({
      objects: 'inventory_objects/getInventoryObjects'
    })
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('protocols/generateProtocol', {object_id: this.object_id, is_excel: false})
            .then(() => {
              this.$store.dispatch('protocols/getProtocols');
              this.$emit('close-dialog');

              this.object_id = null;
              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
