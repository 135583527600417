<template>
  <v-row
    v-if="evaluationsObject">
    <v-col md="12">
      <evaluation-object-info :evaluation-object-name="evaluationsObject.name" />
    </v-col>
    <v-col md="6" sm="12">
      <evaluation-object-tech-states-list :tech-states-list="evaluationsObject.techStates" />
    </v-col>
    <v-col md="6" sm="12">
      <evaluation-object-defects-list
        :defects-list="evaluationsObject.defects"
        :tech-states-list="evaluationsObject.techStates"/>
    </v-col>
  </v-row>
</template>

<script>
import EvaluationObjectDefectsList from "@/components/TCCalculator/EvaluationObjectDefectsList";
import EvaluationObjectTechStatesList from "@/components/TCCalculator/EvaluationObjectTechStatesList";
import EvaluationObjectInfo from "@/components/TCCalculator/EvaluationObjectInfo";
import {mapState} from "vuex";

export default {
  name: "EvaluationObjectPage",
  components: {EvaluationObjectInfo, EvaluationObjectTechStatesList, EvaluationObjectDefectsList},
  computed: {
    ...mapState({
      evaluationsObject: state => state.evaluationObjects.object
    })
  },
  mounted() {
    this.$store.dispatch('evaluationObjectDefects/getDefectValues');
    this.$store.dispatch('evaluationObjects/getEvaluationObject', this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
