<template>
  <div id="map" class="base-map"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

export default {
  name: "BaseMap",
  data() {
    return {
      map: null,
      marker: null,
    }
  },
  props: {
    center: {
      type: Array,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    setMarkerByClick: {
      type: Boolean,
      default: false
    },
    initialCoords: [],
  },
  mounted() {
    this.createMap();

    this.map.on('click', (e) => {
      this.$store.commit('base_map/SET_EVENT_CLICK_COORDS', e.lngLat);

      if (this.setMarkerByClick) this.setMarker(e.lngLat);
    })
    this.setMarker([this.initialCoords[1], this.initialCoords[0]])
  },
  methods: {
    createMap() {
      mapboxgl.accessToken = process.env.VUE_APP_MAP_BOX_API_TOKEN;
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v12',
        center: this.center,
        zoom: this.zoom
      });
    },
    setMarker(coords) {
      if (this.marker !== null) this.marker.remove();
      this.marker = new mapboxgl.Marker()
          .setLngLat(coords)
          .addTo(this.map);
    }
  }
}
</script>

<style scoped>
.base-map {
  height: calc(100vh - 64px);
}
</style>
