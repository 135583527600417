<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>Объекты оценки</v-card-title>
        <v-card-text>
          <evaluation-objects-list :objects-list="evaluationObjectsList"/>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      v-model="createDialog"
      max-width="600px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          color="primary"
          dark
          fixed
          bottom
          right
          fab
          v-bind="attrs"
          v-on="on"
          @click="createDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <evaluation-object-form @success-request="createDialog = false"/>
    </v-dialog>
  </v-row>
</template>

<script>
import EvaluationObjectsList from "@/components/TCCalculator/EvaluationObjectsList.vue";
import EvaluationObjectForm from "@/components/TCCalculator/EvaluationObjectForm.vue";
import {mapState} from "vuex";

export default {
  name: "EvaluationObjectsListPage",
  components: {EvaluationObjectForm, EvaluationObjectsList},
  data() {
    return {
      createDialog: false
    }
  },
  computed: {
    ...mapState({
      evaluationObjectsList: state => state.evaluationObjects.objectsList
    })
  },
  mounted() {
    this.$store.dispatch('evaluationObjects/getEvaluationObjects');
  }
}
</script>

<style scoped>

</style>
