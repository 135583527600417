import ProjectsService from "@/services/ProjectsService";

export const namespaced = true;

export const state = {
    projects: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_PROJECTS(state, projects) {
        state.projects = projects;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getProjects({commit}) {
        commit("SET_LOADING", true);
        ProjectsService.getProjects()
            .then(projects => commit("SET_PROJECTS", projects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createProject({commit}, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ProjectsService.createProject(payload)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    },
    updateProject({commit}, {projectId, payload}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ProjectsService.updateProject({projectId, payload})
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    },
    deleteProject({commit}, projectId) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ProjectsService.deleteProject(projectId)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    }
}

export const getters = {
    getProjects: state => {
        return state.projects;
    },
    getProject: state => id => {
        return state.projects.find(item => item.id === parseInt(id));
    },
    getProjectName: state => id => {
        const project = state.projects.find(project => project.id === id)
        if (project) return project.name;
    }
}