export const namespaced = true;

export const state = {
    center: [30.310485, 59.946186],
    zoom: 9,
    eventClickCoords: []
}

export const mutations = {
    SET_CENTER(state, coords) {
        state.center = coords;
    },
    SET_ZOOM(state, zoom) {
        state.zoom = zoom;
    },
    SET_EVENT_CLICK_COORDS(state, coords) {
        state.eventClickCoords = coords;
    }
}

export const getters = {
    getCenter: state => {
        return state.center;
    },
    getZoom: state => {
        return state.zoom;
    },
    getEventClickCoords: state => {
        return state.eventClickCoords;
    }
}