<template>
  <v-card
      elevation="2"
      class="mt-4"
  >
    <v-card-text v-if="objectParams.length">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th v-for="(item, index) in componentParams"
                :key="index">
              {{ parameterName(item.id) }}
            </th>
            <th>Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, row_index) in objectParams"
              :key="row_index">
            <td v-for="(item, index) in componentParams"
                :key="index">
              <div v-if="getValueRow(row_index, item.id) != null">
                <div v-if="getValueRow(row_index, item.id).parameter_type === 'FILE'">
                  <a v-if="getFileType(getValueRow(row_index, item.id).value) === 'pdf'"
                     :href="getValueRow(row_index, item.id).value"
                     target="_blank"
                     class="show-image">
                    Открыть файл
                  </a>
                  <span v-else
                        @click="showImage(getValueRow(row_index, item.id).value)"
                        class="show-image">Показать фото</span>
                </div>

                <div v-else-if="getValueRow(row_index, item.id).parameter_type === 'MULTI_PHOTO'">
                  <div
                      v-if="getValueRow(row_index, item.id).value ? getValueRow(row_index, item.id).value.length : false"
                      class="photo-preview__container"
                  >
                    <img
                        v-for="url in getValueRow(row_index, item.id).value.split(',')"
                        @click="showImage(url)"
                        :key="url"
                        alt=""
                        class="photo-preview"
                        :src="url">
                  </div>
                </div>

                <div v-else-if="getValueRow(row_index, item.id).parameter_type === 'PHOTO_DEFECTS'">
                  <object-photo-defects :row="getValueRow(row_index, item.id)"/>
                </div>

                <div v-else>{{ getValueRow(row_index, item.id).value }}</div>
              </div>
            </td>
            <td>
              <v-icon
                  class="cursor-pointer"
                  @click="$emit('update-parameters-row', row)"
              >mdi-text-box-edit
              </v-icon>
              <v-icon style="margin-left: 31px"
                      v-if="authUser.isHasRole('admin')"
                      color="red"
                      class="cursor-pointer"
                      @click="$emit('delete-parameters-row', row)"
              >mdi-trash-can-outline
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card-text>
    <v-card-text
        v-else
        class="text-center"
    >
      Параметры не найдены
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import VueViewer from 'v-viewer'
import Vue from 'vue'
import ObjectPhotoDefects from "@/components/InventoryObject/ObjectPhotoDefects.vue";

Vue.use(VueViewer);

export default {
  name: "ObjectParametersList",
  components: {ObjectPhotoDefects},
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      parameterName: 'parameters/getParameterName',
      objectParams: 'inventory_objects_params/getObjectParameters',
      componentParams: 'parameters/getParameters',
      objectRow: 'inventory_objects_params/getRowByIndex',
      getValueRow: 'inventory_objects_params/getValueFromRow',
      authUser: 'auth/authUser',
    })
  },
  methods: {
    getFileType(url) {
      if (url == null) {
        return '';
      }
      return url.split('.').pop()
    },
    showImage(image) {
      this.$viewerApi({
        images: [image],
        options: {
          navbar: false,
          title: false,
          toolbar: false
        }
      })
    }
  }
}
</script>

<style scoped>
.show-image {
  color: #1178bd;
  font-weight: 600;
  cursor: pointer;
}

.photo-preview__container {
  display: flex;
  gap: 10px;
}

.photo-preview {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
}
</style>
