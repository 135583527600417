<template>
  <v-card elevation="2">
    <v-card-title>Справочники</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Наименование</th>
              <th class="text-left">Тип</th>
              <th class="text-left">Управление</th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="item in directories"
                :key="item.id"
            >
              <td>
                <router-link :to="`/directory/${item.id}`"
                             class="cursor-pointer">
                  {{item.id}}
                </router-link>
              </td>
              <td>{{item.name}}</td>
              <td>{{item.getTypeLabel()}}</td>
              <td>
                <v-icon @click="openDirectoryModal(item.id)"
                        class="cursor-pointer mr-2">mdi-text-box-edit</v-icon>
                <v-icon @click="deleteDirectory(item.id)"
                              class="cursor-pointer">mdi-delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-card>
          <v-card-title>Изменить справочник</v-card-title>
          <v-card-text>
            <v-text-field
                v-model="payload.name"
                :rules="notNullRule"
                label="Наименование"
                required
            ></v-text-field>
            <v-select
                v-model="payload.type"
                :rules="notNullRule"
                label="Тип справочника"
                :items="select_options"
                required
            ></v-select>
            <v-btn
                @click="updateDirectory"
                block
                color="primary"
            >Сохранить</v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DirectoriesList",
  data() {
    return {
      fields: [
        {key: 'id', label: 'id'},
        {key: 'name', label: 'Наименование'},
        {key: 'controls', label: 'Управление'}
      ],
      payload: {
        name: null,
        type: null
      },
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      current_directory_id: null,
      dialog: false,
      valid: true,
      select_options: [
        {value: 1, text: 'Текстовый'},
        {value: 2, text: 'Фото'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      directories: 'directories/getDirectories',
      directory: 'directories/getDirectoryById'
    })
  },
  methods: {
    openDirectoryModal(id) {
      this.current_directory_id = id;
      const directory = this.directory(id);
      this.payload.name = directory.name;
      this.payload.type = directory.type;
      this.dialog = true;
    },
    updateDirectory() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('directories/updateDirectory', {
          directory_id: this.current_directory_id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('directories/getDirectories');
              this.dialog = false;
              this.$refs.form.resetValidation();
            })
      }
    },
    deleteDirectory(id) {
      this.$store.dispatch('directories/deleteDirectory', id)
          .then(() => {
            this.$store.dispatch('directories/getDirectories');
          })
    }
  }
}
</script>

<style scoped>

</style>