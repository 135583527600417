<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Наименование</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in categories"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <v-icon
                  @click="updateCategory(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-text-box-edit
              </v-icon>
              <v-icon
                  @click="deleteCategory(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
        v-model="updateDialog"
        max-width="600px"
    >
      <category-update-form
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import CategoryUpdateForm from "@/components/InitialData/CategoryUpdateForm";

export default {
  name: "CategoriesList",
  components: {CategoryUpdateForm},
  data() {
    return {
      updateDialog: false
    }
  },
  computed: {
    ...mapGetters({
      categories: 'initialDataCategories/getCategories'
    })
  },
  methods: {
    updateCategory(id) {
      this.$store.dispatch('initialDataCategories/getInitialDataCategory', id)
          .then(() => {
            this.updateDialog = true;
          })
    },
    deleteCategory(id) {
      this.$store.dispatch('initialDataCategories/deleteInitialDataCategory', id)
          .then(() => {
            this.$store.dispatch('initialDataCategories/getInitialDataCategories');
          })
    }
  }
}
</script>

<style scoped>

</style>