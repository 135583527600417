<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col v-if="active_component_id"
             @click="dialog = true"
             md="12">
          <div class="add-list-item">Добавить параметр</div>
        </v-col>
        <v-col v-for="parameter in parameters"
             :key="parameter.id"
             md="12">
          <div class="list-item">
            <div class="list-item-name">{{ parameter.name }}</div>
            <div class="list-item-description">Код: {{ parameter.parameter_code }}</div>
            <div class="list-item-description">Тип: {{ parameter.type }}</div>

            <v-btn-toggle active-class="active-none" class="mt-3">

              <v-btn
                  color="blue"
                  x-small
                  @click="updateParameter(parameter)"
              >
                <v-icon small color="white">
                  mdi-text-box-edit
                </v-icon>
              </v-btn>

              <v-btn
                  :color="parameter.activity ? 'blue' : 'grey'"
                  x-small
                  @click="toggleActivity(parameter)"
              >
                <v-icon small color="white">
                  mdi-power
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="updateDialog"
      max-width="600px"
    >
      <update-parameter-form
        :dialog-state="updateDialog"
        :parameter="selectedParameter"
        @success-request="updateDialog = false"
      />
    </v-dialog>

    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <create-parameter-form @success-request="dialog = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import CreateParameterForm from "@/components/ObjectType/CreateParameterForm";
import UpdateParameterForm from "@/components/ObjectType/UpdateParameterForm";

export default {
  name: "ParametersList",
  components: {UpdateParameterForm, CreateParameterForm},
  data() {
    return {
      dialog: false,
      updateDialog: false,
      selectedParameter: null
    }
  },
  computed: {
    ...mapGetters({
      active_component_id: 'components/getActiveComponentId',
      parameters: 'parameters/getParameters'
    })
  },
  methods: {
    updateParameter(parameter) {
      this.selectedParameter = parameter;
      this.updateDialog = true;
    },
    toggleActivity(parameter) {
      if (parameter.activity) this.$store.dispatch('parameters/deactivateParameter', parameter.id)
          .then(() => this.$store.dispatch('parameters/getComponentParameters', {
            object_type_id: this.$store.state.object_types.active_object_type_id,
            component_id: parameter.component_id
          }))
      else this.$store.dispatch('parameters/activateParameter', parameter.id)
          .then(() => this.$store.dispatch('parameters/getComponentParameters', {
            object_type_id: this.$store.state.object_types.active_object_type_id,
            component_id: parameter.component_id
          }))
    }
  }
}
</script>

<style scoped>

</style>