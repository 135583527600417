<template>
  <v-card>
    <v-card-title v-if="formType === 'create'">Добавить инженера</v-card-title>
    <v-card-title v-else>Изменить инженера</v-card-title>
    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >
        <v-text-field
            v-model="payload.full_name"
            :rules="fullNameRules"
            label="ФИО"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.short_name"
            :rules="shortNameRules"
            label="Фамилия с инициалами"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.position"
            :rules="positionRules"
            label="Должность"
            required
        ></v-text-field>

        <v-select
            v-model="payload.devices"
            label="Устройства"
            :items="devices.map(device => {
            return {value: device.id, text: device.serial_number + ' - ' + device.name }
          })"
            multiple
            required
        ></v-select>

        <v-btn
            v-if="formType === 'create'"
            color="primary"
            block
            @click="createEngineer()"
        >Добавить
        </v-btn>

        <v-btn
            v-else
            color="primary"
            block
            @click="updateEngineer()"
        >Изменить
        </v-btn>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EngineersForm",
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      payload: {
        full_name: null,
        short_name: null,
        position: null,
        devices: []
      },
      fullNameRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      shortNameRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      positionRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  computed: {
    ...mapGetters({
      devices: 'devices/getDevices'
    })
  },
  watch: {
    formType: function (value) {
      if (value === 'create') {
        this.payload = {
          full_name: null,
          short_name: null,
          position: null,
          devices: []
        }
        this.$refs.form.resetValidation();
      } else if (value === 'update') {
        this.setFields();
      }
    }
  },
  mounted() {
    this.$store.dispatch('devices/getDevices');
    this.setFields();
  },
  methods: {
    setFields() {
      const engineer = this.$store.getters['engineers/getEngineer'];

      this.payload.full_name = engineer.full_name;
      this.payload.short_name = engineer.short_name;
      this.payload.position = engineer.position;
      this.payload.devices = engineer.devices.map(device => {
        return device.id;
      });
    },
    createEngineer() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('engineers/createEngineer', this.payload)
            .then(() => {
              this.$store.dispatch('engineers/getEngineers');
              this.payload = {
                full_name: null,
                short_name: null,
                position: null,
                devices: []
              }

              this.$refs.form.resetValidation();
              this.$emit('close-dialog');
            })
      }
    },
    updateEngineer() {
      const engineer = this.$store.getters['engineers/getEngineer'];
      if (this.$refs.form.validate()) {
        this.$store.dispatch('engineers/updateEngineer', {
          engineer_id: engineer.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('engineers/getEngineers');

              this.$refs.form.resetValidation();
              this.$emit('close-dialog');
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
