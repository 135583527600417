export class EngineerDetailReportItem {
    constructor(params) {
        this.object_id = params.object_id;
        this.object_name= params.object_name;
        this.object_address= params.object_address;
        this.object_event_at= params.object_event_at;
        this.user_id= params.user_id;
        this.user_name= params.user_name;
        this.object_type_name= params.object_type_name;
    }
}
