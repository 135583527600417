<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        app

    >
      <the-sidebar />
    </v-navigation-drawer>

    <v-app-bar
        app
        color="primary"
        dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{$route.name}}
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import TheSidebar from "@/layout/TheSidebar";
export default {
  name: "MainTemplate",
  components: {TheSidebar},
  data() {
    return {
      drawer: true
    }
  }
}
</script>

<style scoped>

</style>