import * as API from '@/services/API'
import {Device} from "@/models/Device";

export default {
    getDevices() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/devices`)
                .then(response => resolve(response.data.data.map(item => new Device(item))))
                .catch(error => reject(error));
        })
    },
    getDevice(device_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/devices/${device_id}`)
                .then(resposne => resolve(new Device(resposne.data.data)))
                .catch(error => reject(error));
        })
    },
    createDevice(payload) {
        return API.apiClient.post(`/api/catalogs/devices`, payload);
    },
    updateDevice({device_id, payload}) {
        return API.apiClient.patch(`/api/catalogs/devices/${device_id}`, payload);
    },
    deleteDevice(device_id) {
        return API.apiClient.delete(`/api/catalogs/devices/${device_id}`);
    }
}