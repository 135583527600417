export default class Component {
    constructor(params) {
        this.id = params.id;
        this.object_type_id = params.object_type_id;
        this.name = params.name;
        this.component_code = params.component_code;
        this.activity = params.activity;
        this.is_form = params.is_form
    }
}
