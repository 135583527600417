import * as API from './API';
import EvaluationObject from "@/models/TCCalculator/EvaluationObject";
import Defect from "@/models/TCCalculator/Defect";
import TechState from "@/models/TCCalculator/TechState";

export default {
    fetchEvaluationObjectsList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/tcc/eval-objects`)
                .then(response => resolve(response.data.data
                    .map(item => new EvaluationObject(item))))
                .catch(error => reject(error))
        })
    },
    fetchEvaluationObject(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/tcc/eval-objects/${id}`)
                .then(response => {
                    const {id, name, defects, tech_states} = response.data.data;
                    const evalObject = new EvaluationObject({id, name});
                    evalObject.defects = defects.map(item => new Defect(item));
                    evalObject.techStates = tech_states.map(item => new TechState(item));
                    resolve(evalObject);
                })
                .catch(error => reject(error))
        })
    },
    createEvaluationObject(categoryId, payload) {
        return API.apiClient.post(`/api/catalogs/tcc/eval-objects`, payload);
    },
    updateEvaluationObject(id, payload) {
        return API.apiClient.patch(`/api/catalogs/tcc/eval-objects/${id}`, payload);
    }
}
