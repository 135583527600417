<template>
  <v-col md="12">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="11">
          <v-text-field
              v-model="term"
              label="Поиск (имя, адрес, ид, номер по АБ, номер по РУ)"
              required
              @change="termChanges"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
              title="Сбросить фильтры"
              class="mx-2"
              fab
              dark
              color="primary"
              @click="resetFilter">
            <v-icon dark>
              mdi-cached
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Наименование объекта</th>
          <th class="text-left">Статус</th>
          <th class="text-left">Ссылка</th>
          <th class="text-left">Дата создания</th>
          <th class="text-left">Опции</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in protocols"
            :key="item.id"
        >
          <td>{{ item.object_name }}</td>
          <td>{{ item.getStateLabel() }}</td>
          <td>
            <a
                v-if="item.url"
                :href="item.url"
            >Скачать</a>
          </td>
          <td>{{ item.created_at }}</td>
          <td><a @click="deleteProtocol(item.id)" v-if="!isDeleting">Удалить</a></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProtocolsList",
  computed: {
    ...mapGetters({
      protocols: 'protocols/getProtocols'
    })
  },
  data() {
    return {
      term: '',
      isDeleting: false,
    }
  },
  methods: {
    termChanges() {
      this.$store.dispatch('protocols/getProtocols', this.term);
    },
    resetFilter() {
      this.term = '';
      this.$store.dispatch('protocols/getProtocols', this.term);
    },
    deleteProtocol(protocol_id) {
      this.isDeleting = true;
      this.$store.dispatch('protocols/deleteProtocol', protocol_id)
          .then(() => {
            this.$store.dispatch('protocols/getProtocols');
            this.isDeleting = false;
          }).catch(() => {
        this.isDeleting = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
