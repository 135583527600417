<template>
  <v-form
    v-model="valid"
    lazy-validation
  >
    <v-text-field
        v-model="payload.email"
        :rules="notNullRule"
        label="Email"
        required
    ></v-text-field>

    <v-text-field
        v-model="payload.password"
        :append-icon="textType ? 'mdi-eye' : 'mdi-eye-off'"
        :type="textType ? 'text' : 'password'"
        :rules="notNullRule"
        label="Пароль"
        required
        @click:append="textType = !textType"
    ></v-text-field>

    <div class="red--text">{{error}}</div>

    <v-btn
        dark
        color="blue"
        block
        @click="login()"
    >Авторизоваться
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      valid: false,
      textType: false,
      notNullRule: [ v => !!v || 'Поле обязательно для заполнения' ],
      payload: {
        email: null,
        password: null
      },
      error: null
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('auth/login', this.payload);
        const authUser = await this.$store.dispatch("auth/getAuthUser");
        if (authUser) {
          await this.$store.dispatch("auth/setGuest", {value: "isNotGuest"});
          await this.$router.push("/");
        } else {
          const error = Error(
              "Не удается получить данные пользователя, проверьте настройки API."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.$store.commit("auth/SET_LOADING", false);
        if (typeof error.response.data === 'string') this.error = 'ошибка API, попробуйте ещё раз.'
        else this.error = error.response.data.errors;
      }
    }
  }
}
</script>

<style scoped>

</style>