import * as API from './API'
import User from "@/models/User";

export default {
    fetchUsers() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/users/`)
                .then(response => resolve(response.data.data.map(item => new User(item))))
                .catch(error => reject(error))
        })
    },
    getUser(userId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/users/${userId}`)
                .then(response => resolve(new User(response.data.data)))
                .catch(error => reject(error))
        })
    },
    createUser(payload) {
        return API.apiClient.post(`/api/users`, payload);
    },
    updateUser(userId, payload) {
        return API.apiClient.patch(`/api/users/${userId}`, payload);
    },
    resetPassword(userId, payload) {
        return API.apiClient.post(`/api/users/${userId}/password`, payload);
    },
    banUser(userId) {
        return API.apiClient.post(`/api/users/${userId}/ban`);
    },
    unbanUser(userId) {
        return API.apiClient.post(`/api/users/${userId}/unban`);
    }
}
