<template>
  <div>
    <v-file-input
      :label="parameterName"
      multiple
      counter
      show-size
      @change="addPhotos"
    >
    </v-file-input>

    <div class="photo-preview">
      <div
        v-for="(photo, index) in filesList"
        :key="index"
        class="photo-preview__wrapper">

        <div class="photo-preview__delete">
          <v-icon
            @click="removePhoto(index, photo)"
            color="white">mdi-delete</v-icon>
        </div>

        <img
          v-if="typeof photo === 'object'"
          :src="createPhotoPreviewUrl(photo)"
          alt="preview"
          class="photo-preview__img">

        <img
          v-else-if="typeof photo === 'string'"
          :src="photo"
          alt="preview"
          class="photo-preview__img">
      </div>
    </div>
  </div>
</template>

<script>
import {createPhotoPreviewUrl} from "@/utils/helpers";
import {emitter} from "@/utils/emmiter";

export default {
  name: "MultiPhoto",
  props: {
    parameterName: {
      type: String,
      required: true
    },
    parameterValue: {},
    parameterRawValue: {},
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filesList: []
    }
  },
  watch: {
    filesList: function (value) {
      this.$emit('update:modelValue', value);
    },
    parameterValue: function (value) {
      if (typeof value === 'string') {
        const rawFilesArr = value.split(';')
        this.filesList = rawFilesArr[0].split(',');
      }
    }
  },
  mounted() {
    if (typeof this.parameterValue === 'string') {
      const rawFilesString = this.parameterValue;
      const rawFilesArr = rawFilesString.split(';')
      this.filesList = rawFilesArr[0].split(',');
    }

    emitter.on('parameters-form-submit', () => {
      this.filesList = [];
    })
  },
  methods: {
    createPhotoPreviewUrl,
    addPhotos(files) {
      this.filesList.push(...files);
    },
    removePhoto(index, value) {
      this.filesList.splice(index, 1);

      if (this.editMode && typeof value === 'string') {
        const parameterRaw = this.parameterRawValue;
        const rawValueArray = parameterRaw.split(',');
        const raw_value = rawValueArray.find(rawValue => value.includes(rawValue));
        this.$emit('update:deletedValues', raw_value);
      }
    }
  }
}
</script>

<style scoped>
.photo-preview {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.photo-preview__wrapper {
  width: 100px;
  height: 100px;

  position: relative;
}

.photo-preview__delete {
  opacity: 0;
  visibility: hidden;

  width: 100%;
  height: 100%;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, .6);

  transition: .5s;
}

.photo-preview__wrapper:hover .photo-preview__delete {
  opacity: 1;
  visibility: visible;
}

.photo-preview__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
