<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col md="12">
          <div
            @click="dialog = true"
            class="add-list-item"
          >Добавить тип объекта</div>
        </v-col>
        <v-col v-for="objectType in objectTypes"
               :key="objectType.id"
               @click="getComponents(objectType.id)"
               md="12">
          <div class="list-item"
               :class="{active: active_object_type_id === objectType.id}">
            <div class="object-card-title">
              <div class="list-item-name">{{ objectType.name }}</div>

            </div>
            <div class="list-item-description">Тип объекта #{{ objectType.id }}</div>
            <v-btn-toggle active-class="active-none" class="mt-3">

              <v-btn
                  color="blue"
                  dark
                  @click="templateDialog = true"
                  x-small
              >Загрузить шаблон
              </v-btn>

              <v-btn
                  color="blue"
                  x-small
                  @click="updateObjectType(objectType.id)"
              >
                <v-icon small color="white">
                  mdi-text-box-edit
                </v-icon>
              </v-btn>

              <v-btn
                  :color="objectType.activity ? 'blue' : 'grey'"
                  x-small
                  @click="toggleActivity(objectType.id, objectType.activity)"
              >
                <v-icon small color="white">
                  mdi-power
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="updateDialog"
      max-width="600px"
    >
      <update-object-type-form
          @success-request="updateDialog = false"
          :dialog-state="updateDialog"
          :object-type-id="selectedObjectTypeId"
      />
    </v-dialog>

    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <create-object-type-form @success-request="dialog = false"/>
    </v-dialog>

    <v-dialog
        v-model="templateDialog"
        max-width="600px">
      <v-card>
        <v-card-title>Загрузить шаблон</v-card-title>
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-file-input
                v-model="template"
                :rules="[
                    v => !!v || 'Поле обязательно для заполнения'
                ]"
                label="Шаблон протокола"
                required
            ></v-file-input>
            <v-btn
                block
                color="primary"
                class="mt-2"
                @click="uploadTemplate"
            >
              Загрузить
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import CreateObjectTypeForm from "@/components/ObjectType/CreateObjectTypeForm";
import UpdateObjectTypeForm from "@/components/ObjectType/UpdateObjectTypeForm";

export default {
  name: "ObjectTypesList",
  components: {UpdateObjectTypeForm, CreateObjectTypeForm},
  data() {
    return {
      dialog: false,
      templateDialog: false,
      updateDialog: false,
      valid: true,
      template: null,
      selectedObjectTypeId: null
    }
  },
  computed: {
    ...mapGetters({
      objectTypes: 'object_types/getObjectTypes',
      active_object_type_id: 'object_types/getActiveObjectTypeId'
    })
  },
  methods: {
    getComponents(object_type_id) {
      this.$store.commit('object_types/SET_ACTIVE_OBJECT_TYPE_ID', object_type_id);
      this.$store.commit('components/SET_ACTIVE_COMPONENT_ID', null);
      this.$store.commit('parameters/SET_PARAMETERS', []);
      this.$store.dispatch('components/getComponents', object_type_id);
    },
    uploadTemplate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('object_types/uploadTemplate', {
          object_type_id: this.active_object_type_id,
          template: this.template
        })
            .then(() => {

              this.templateDialog = false;
            })
      }
    },
    toggleActivity(id, activity) {
      if (activity) this.$store.dispatch('object_types/deactivateObjectType', id)
          .then(() => this.$store.dispatch('object_types/getObjectTypes'));
      else this.$store.dispatch('object_types/activateObjectType', id)
          .then(() => this.$store.dispatch('object_types/getObjectTypes'));

    },
    updateObjectType(id) {
      this.updateDialog = true;
      this.selectedObjectTypeId = id;
    }
  }
}
</script>

<style scoped>
.object-card-title {
  display: flex;
  justify-content: space-between;
}
</style>