<template>
  <v-card>
    <v-card-title>Изменить тип объекта</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.description"
            :rules="notNullRule"
            label="Описание"
            required
        ></v-text-field>
        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UpdateObjectTypeForm",
  props: ['dialogState', 'objectTypeId'],
  data() {
    return {
      valid: false,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null,
        description: null
      }
    }
  },
  watch: {
    dialogState: function (state) {
      if (state) {
        this.fillFields()
      }
    }
  },
  computed: {
    ...mapGetters({
      objectType: 'object_types/getObjectTypeFromList'
    })
  },
  mounted() {
    this.fillFields();
  },
  methods: {
    fillFields() {
      const objectType = this.objectType(this.objectTypeId);
      this.payload.name = objectType.name;
      this.payload.description = objectType.description;
    },
    submitForm() {
      this.$store.dispatch('object_types/updateObjectType', {
        typeId: this.objectTypeId,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('object_types/getObjectTypes');
            this.$emit('success-request');

            this.payload = {
              name: null,
              description: null
            }

            this.$refs.form.resetValidation()
          })
    }
  }
}
</script>

<style scoped>

</style>