<template>
  <v-row>
    <v-col md="12">
      <devices-list
          @update-device="formType = 'update'"
          @open-dialog="dialog = true"
      />
    </v-col>

    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
            @click="formType = 'create'"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <devices-form
          :form-type="formType"
          @close-dialog="dialog = false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import DevicesList from "@/components/Device/DevicesList";
import DevicesForm from "@/components/Device/DevicesForm";
export default {
  name: "DevicesListPage",
  components: {DevicesForm, DevicesList},
  data() {
    return {
      dialog: false,
      formType: 'create'
    }
  },
  mounted() {
    this.$store.dispatch('devices/getDevices');
  }
}
</script>

<style scoped>

</style>