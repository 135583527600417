<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Имя</th>
              <th class="text-left">E-mail</th>
              <th class="text-left">Управление</th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="item in users"
                :key="item.id"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>
                <v-icon
                    @click="updateUser(item.id)"
                    class="cursor-pointer mr-2"
                >mdi-text-box-edit
                </v-icon>
                <v-icon
                    @click="resetPassword(item.id)"
                    class="cursor-pointer mr-2"
                >mdi-lock-reset
                </v-icon>
                <v-icon
                    v-if="!item.isBanned()"
                    @click="banUser(item.id)"
                    class="cursor-pointer mr-2"
                >mdi-shield-alert
                </v-icon>
                <v-icon
                    v-if="item.isBanned()"
                    @click="unbanUser(item.id)"
                    class="cursor-pointer mr-2"
                >mdi-shield-off
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
      v-model="updateDialog"
      max-width="600px"
    >
      <user-update-form
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-model="changePasswordDialog"
      max-width="600px"
    >
      <user-reset-password-form @success-request="changePasswordDialog = false" />
    </v-dialog>

  </v-card>
</template>

<script>
import UserUpdateForm from "@/components/Users/UserUpdateForm";
import {mapGetters} from "vuex";
import UserResetPasswordForm from "@/components/Users/UserResetPasswordForm";
export default {
  name: "UsersList",
  components: {UserResetPasswordForm, UserUpdateForm},
  data() {
    return {
      updateDialog: false,
      changePasswordDialog: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/getUsers'
    })
  },
  methods: {
    updateUser(id) {
      this.$store.dispatch('users/getUser', id)
          .then(() => {
            this.updateDialog = true;
          })
    },
    resetPassword(id) {
      this.$store.dispatch('users/getUser', id)
          .then(() => {
            this.changePasswordDialog = true;
          })
    },
    banUser(id) {
      this.$store.dispatch('users/banUser', id)
          .then(() => {
            this.$store.dispatch('users/getUsers')
          })
    },
    unbanUser(id) {
      this.$store.dispatch('users/unbanUser', id)
          .then(() => {
            this.$store.dispatch('users/getUsers')
          })
    }
  }
}
</script>

<style scoped>

</style>
