<template>
  <v-col md="12">
    <v-card elevation="2">
      <v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="3">
              <v-menu
                  v-model="picker_start"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="start_period"
                      label="Начало периода"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                    v-model="start_period"
                    locale="ru-ru"
                    :first-day-of-week="1"
                    @input="picker_start = false"
                    @change="getReport"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                  v-model="picker_end"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="end_period"
                      label="Начало периода"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                    v-model="end_period"
                    locale="ru-ru"
                    :first-day-of-week="1"
                    @input="picker_end = false"
                    @change="getReport"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                  v-model="user_id"
                  label="Пользователь"
                  :items="[{value: '', text: 'Все'}].concat(users.map(user => {return {value: user.id, text: user.name}}))"
                  required
                  @change="getReport"
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn
                  @click="resetFilter"
                  title="Сбросить фильтры"
                  class="mx-2"
                  fab
                  dark
                  color="primary">
                <v-icon dark>
                  mdi-cached
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <engineer-detail-results-list :items="items"/>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import EngineerDetailResultsList from "@/components/Reports/EngineerDetailResultsList.vue";
import reportService from "@/services/ReportService";
import moment from 'moment';
import {mapGetters} from "vuex";

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  }
});
export default {
  name: "EngineerDetailResultsPage",
  components: {EngineerDetailResultsList},
  data() {
    return {
      items: [],
      start_period: moment().startOf('week').format('YYYY-MM-DD'),
      end_period: moment().endOf('week').format('YYYY-MM-DD'),
      user_id: null,
      picker_start: false,
      picker_end: false,
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/getUsers'
    })
  },
  mounted() {
    this.$store.dispatch('users/getUsers');
  },
  methods: {
    getReport() {
      reportService.getEngineerDetailResultsReport(this.start_period, this.end_period, this.user_id).then((results) => {
        this.items = results;
      });
    },
    resetFilter() {
      this.start_period = moment().startOf('week').format('YYYY-MM-DD');
      this.end_period = moment().endOf('week').format('YYYY-MM-DD');
      this.user_id = null;
    },
  }
}
</script>

<style scoped>

</style>
