import ProtocolService from "@/services/ProtocolService";

export const namespaced = true;

export const state = {
    protocols: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_PROTOCOLS(state, protocols) {
        state.protocols = protocols;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getProtocols({commit}, term) {
        commit("SET_LOADING", true);
        ProtocolService.getProtocols(term === undefined ? '' : term)
            .then(protocols => commit("SET_PROTOCOLS", protocols))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    generateProtocol({commit}, {object_id, is_excel = false}) {
        return new Promise((resolve, reject) => {
            ProtocolService.generateProtocol(object_id, is_excel)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteProtocol({commit}, protocol_id) {
        return new Promise((resolve, reject) => {
            ProtocolService.deleteProtocol(protocol_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getProtocols: state => {
        return state.protocols;
    }
}
