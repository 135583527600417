export class Protocol {
    constructor(params) {
        this.id = params.id;
        this.state = params.state;
        this.object_name = params.object_name;
        this.url = params.url;
        this.created_at = params.created_at;
    }

    getStateLabel() {
        switch (this.state) {
            case 1:
                return 'Генерация'
            case 2:
                return 'Успешно'
            case 3:
                return 'Ошибка'
        }
    }
}