import * as API from '@/services/API'
import Project from "@/models/Project";

export default {
    getProjects() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/api/projects')
                .then(response => resolve(response.data.data.map(item => new Project(item))))
                .catch(error => reject(error));
        })
    },
    createProject(payload) {
        return API.apiClient.post('/api/projects', payload);
    },
    updateProject({projectId, payload}) {
        return API.apiClient.patch(`/api/projects/${projectId}`, payload);
    },
    deleteProject(projectId) {
        return API.apiClient.delete(`/api/projects/${projectId}`);
    }
}