<template>
  <v-app>
    <v-main>
      <v-container fill-height fluid>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LoginTemplate"
}
</script>

<style scoped>

</style>