import InitialDataCategoriesService from "@/services/InitialDataCategoriesService";

export const namespaced = true;

export const state = {
    categories: [],
    category: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
    SET_CATEGORY(state, category) {
        state.category = category;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getInitialDataCategories({commit}) {
        commit("SET_LOADING", true);
        InitialDataCategoriesService.fetchInitialDataCategories()
            .then(categories => commit("SET_CATEGORIES", categories))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getInitialDataCategory({commit}, id) {
        return new Promise((resolve, reject) => {
            InitialDataCategoriesService.fetchInitialDataCategory(id)
                .then(category => {
                    commit("SET_CATEGORY", category);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    createInitialDataCategory({commit}, payload) {
        return new Promise((resolve, reject) => {
            InitialDataCategoriesService.createInitialDataCategory(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateInitialDataCategory({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            InitialDataCategoriesService.updateInitialDataCategory(id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteInitialDataCategory({commit}, id) {
        return new Promise((resolve, reject) => {
            InitialDataCategoriesService.deleteInitialDataCategory(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getCategories: state => {
        return state.categories;
    },
    getCategory: state => {
        return state.category;
    },
    getCategoryFromList: state => id => {
        return state.categories.find(item => item.id === parseInt(id));
    },
    getCategoryName: state => id => {
        const category = state.categories.find(item => item.id === parseInt(id));
        return category ? category.name : 'Категория не найдена'
    }
}