export default class Comment {
    constructor(params) {
        this.id = params.id;
        this.autor = params.user.name;
        this.date = params.created_at;
        this.comment = params.comment;
    }

    getTitle() {
        return this.autor+" - "+this.date;
    }
}
