<template>
  <v-card style="width: 100%">
    <v-card-title>Обновить объект инвентаризации</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
            xl="6"
            xs="12"
        >
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-select
                v-model="payload.project_id"
                :rules="notNullRule"
                label="Проект"
                :items="projects.map(project => {
            return {value: project.id, text: project.name}
          })"
                required
            ></v-select>
            <v-select
                v-model="payload.construction_type"
                :items="constructionTypes"
                :rules="notNullRule"
                label="Тип конструкции"
                item-text="name"
                item-value="id"
                required
            ></v-select>

            <v-text-field
                v-model="payload.name"
                :rules="notNullRule"
                label="Наименование объекта"
                required
            ></v-text-field>

            <v-text-field
                v-model="payload.subscriber_base_number"
                :rules="notNullRule"
                label="Номер по абонентской базе"
                required
            ></v-text-field>
            <v-text-field
                v-model="payload.district"
                :rules="notNullRule"
                label="Районное управление"
                required
            ></v-text-field>
            <v-text-field
                v-model="payload.address"
                :rules="notNullRule"
                label="Адрес объекта"
                required
            ></v-text-field>

            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="payload.event_at"
                    :rules="notNullRule"
                    label="Дата и время проведения"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                  v-model="payload.event_at"
                  locale="ru-ru"
                  :first-day-of-week="1"
                  @input="menu = false"
              ></v-date-picker>
            </v-menu>
            <v-select
                v-model="payload.engineers"
                :items="engineers"
                label="Специалисты"
                item-text="full_name"
                item-value="id"
                multiple
                required
            ></v-select>
            <v-btn
                color="primary"
                block
                @click="submitForm"
            >Сохранить
            </v-btn>
          </v-form>
        </v-col>
        <v-col
            xl="6"
            xs="12"
        >
          <create-object-map :initial-coords="initialCoords"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import CreateObjectMap from "@/components/InventoryObject/CreateObjectMap.vue";

export default {
  name: "UpdateObjectForm",
  components: {CreateObjectMap},
  props: ['dialogState'],
  data() {
    return {
      payload: {
        project_id: null,
        construction_type: null,
        name: null,
        address: null,
        event_at: null,
        subscriber_base_number: null,
        lat: null,
        lon: null,
        engineers: [],
        district: null,
      },
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      valid: true,
      menu: false,
      initialCoords: [],
    }
  },
  computed: {
    ...mapGetters({
      projects: 'projects/getProjects',
      objectTypes: 'object_types/getObjectTypes',
      engineers: 'engineers/getEngineers',
      constructionTypes: 'constructionTypes/getConstructionTypes',
      inventoryObject: 'inventory_objects/getInventoryObject',
      coords: 'base_map/getEventClickCoords',
    })
  },
  watch: {
    dialogState: function (state) {
      if (state) this.setFields();
    }
  },
  mounted() {
    this.$store.dispatch('constructionTypes/getConstructionTypes');
    this.$store.dispatch('engineers/getEngineers');
    this.$store.dispatch('projects/getProjects');
    this.setFields();
  },
  methods: {
    setFields() {
      this.payload = {
        project_id: this.inventoryObject.project_id,
        construction_type: this.inventoryObject.construction_type.id,
        name: this.inventoryObject.name,
        address: this.inventoryObject.address,
        event_at: this.inventoryObject.event_at,
        subscriber_base_number: this.inventoryObject.subscriber_base_number,
        lat: this.inventoryObject.coordinates.lat,
        lon: this.inventoryObject.coordinates.lon,
        engineers: this.inventoryObject.engineers.map(a => a.id),
        district: this.inventoryObject.district,
      }
      this.initialCoords = [
        this.payload.lat,
        this.payload.lon
      ];
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.payload.lat = this.coords.lat;
        this.payload.lon = this.coords.lng;
        this.$store.dispatch('inventory_objects/updateInventoryObject', {
          id: this.inventoryObject.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('inventory_objects/getInventoryObject', this.inventoryObject.id)
              this.$emit('success-request');

              this.payload = {
                project_id: null,
                construction_type: null,
                name: null,
                address: null,
                event_at: null,
                subscriber_base_number: null,
                lat: null,
                lon: null,
                engineers: [],
                district: null,
              }

              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
