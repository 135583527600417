<template>
  <v-row>
    <v-col class="mb-4 mt-4">
      <h1 class="display-1 text-center font-weight-bold mb-3">
        Инвентаризация объектов
      </h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MainPage"
}
</script>

<style scoped>

</style>