<template>
  <v-row>
    <v-col md="12">
      <users-list />
    </v-col>

    <v-dialog
      v-model="createDialog"
      max-width="600px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
            @click="createDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <user-create-form @success-request="createDialog = false" />
    </v-dialog>

  </v-row>
</template>

<script>
import UsersList from "@/components/Users/UsersList";
import UserCreateForm from "@/components/Users/UserCreateForm";
export default {
  name: "UsersListPage",
  components: {UserCreateForm, UsersList},
  data() {
    return {
      createDialog: false
    }
  },
  mounted() {
    this.$store.dispatch('users/getUsers');
  }
}
</script>

<style scoped>

</style>