<template>
  <v-card>
    <v-card-title>Изменить параметр</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.parameter_code"
            :rules="notNullRule"
            label="Символьный код"
            required
        ></v-text-field>
        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UpdateParameterForm",
  props: ['parameter', 'dialogState'],
  data() {
    return {
      valid: false,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null,
        parameter_code: null
      }
    }
  },
  watch: {
    dialogState: function (state) {
      if (state) this.fillFields()
    }
  },
  mounted() {
    this.fillFields();
  },
  methods: {
    fillFields() {
      this.payload.name = this.parameter.name;
      this.payload.parameter_code = this.parameter.parameter_code;
    },
    submitForm() {
      this.$store.dispatch('parameters/updateParameter', {
        parameterId: this.parameter.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('parameters/getComponentParameters', {
              object_type_id: this.$store.state.object_types.active_object_type_id,
              component_id: this.parameter.component_id
            })
            this.$emit('success-request');

            this.payload = {
              name: null,
              parameter_code: null
            }

            this.$refs.form.resetValidation();
          })
    }
  }
}
</script>

<style scoped>

</style>