import * as API from '@/services/API'
import DirectoryItem from "@/models/DirectoryItem";

export default {
    getDirectoryItems(directory_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/directories/${directory_id}/items`)
                .then(response => resolve(
                    response.data.data.map(item => new DirectoryItem(item))
                ))
                .catch(error => reject(error));
        })
    },
    createDirectoryItem({directory_id, payload}) {
        return API.apiClient.post(`/api/catalogs/directories/${directory_id}/items`, payload, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateDirectoryItem({directory_id, item_id, payload}) {
        return API.apiClient.post(`/api/catalogs/directories/${directory_id}/items/${item_id}`, payload, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteDirectoryItem({directory_id, item_id}) {
        return API.apiClient.delete(`/api/catalogs/directories/${directory_id}/items/${item_id}`);
    }
}