import AuthService from "@/services/AuthService";
import router from "@/router";
import User from "@/models/User";

export const namespaced = true;

export const state = {
    user: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export const actions = {
    logout({ commit, dispatch }) {
        return AuthService.logout()
            .then(() => {
                commit("SET_USER", null);
                dispatch("setGuest", { value: "isGuest" });
                if (router.currentRoute.name !== "login")
                    router.push({ path: "/login" });
            })
            .catch(error => {
                commit("SET_ERROR", error);
            });
    },
    login({commit}, payload) {
        commit("SET_LOADING", true);
        return AuthService.login(payload);
    },
    async getAuthUser({ commit }) {
        commit("SET_LOADING", true);
        try {
            const response = await AuthService.getAuthUser();
            commit("SET_USER", new User(response.data.data));
            commit("SET_LOADING", false);
            return response.data;
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_USER", null);
            commit("SET_ERROR", error);
        }
    },
    setGuest(context, { value }) {
        window.localStorage.setItem("guest", value);
    }
}

export const getters = {
    authUser: state => {
        return state.user;
    },
    userName: state => {
        return state.user ? state.user.name : 'Error'
    },
    error: state => {
        return state.error;
    },
    loading: state => {
        return state.loading;
    },
    guest: () => {
        const storageItem = window.localStorage.getItem("guest");
        if (!storageItem) return false;
        if (storageItem === "isGuest") return true;
        if (storageItem === "isNotGuest") return false;
    }
}