import TechStatesService from "@/services/TechStatesService";

export const namespaced = true;

export const actions = {
    createTechState(context, {objectId, payload}) {
        return new Promise((resolve, reject) => {
            TechStatesService.createTechState(objectId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateTechState(context, {objectId, techStateId, payload}) {
        return new Promise((resolve, reject) => {
            TechStatesService.updateTechState(objectId, techStateId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}
