import * as API from '@/services/API'
import InventoryObject from "@/models/InventoryObject/InventoryObject";
import Comment from "@/models/InventoryObject/Comment";
export default {
    getInventoryObjects(filterParams, page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/inventory/objects?page=${page}`, {
                params: filterParams
            }).then(response => {
                resolve({
                    objects: response.data.data.map(inventoryObject => new InventoryObject(inventoryObject)),
                    meta: response.data.meta
                })
            }).catch(error => reject(error))
        })
    },
    createInventoryObject(payload) {
        return API.apiClient.post('/api/inventory/objects', payload)
    },
    updateInventoryObject(id, payload) {
        return API.apiClient.patch(`/api/inventory/objects/${id}`, payload);
    },
    getInventoryObject(object_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/inventory/objects/${object_id}`)
                .then(response => resolve(new InventoryObject(response.data.data)))
                .catch(error => reject(error));
        })
    },
    getComments(object_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/inventory/objects/${object_id}/comments`)
                .then(response =>  resolve(response.data.data.map(comment => new Comment(comment))))
                .catch(error => reject(error));
        })
    },
    createComment(object_id, payload){
        return API.apiClient.post(`/api/inventory/objects/${object_id}/comments`, payload)
    },
    toMistakes(object_id) {
        return API.apiClient.patch(`/api/inventory/objects/${object_id}/mistakes`)
    },
    toFixed(object_id) {
        return API.apiClient.patch(`/api/inventory/objects/${object_id}/fixed`)
    },
    toVerified(object_id) {
        return API.apiClient.patch(`/api/inventory/objects/${object_id}/verified`)
    }
}
