<template>
  <v-row>
    <v-col
        lg="4"
        xs="12">
      <object-types-list />
    </v-col>
    <v-col
        lg="4"
        xs="12">
      <components-list />
    </v-col>
    <v-col
        lg="4"
        xs="12">
      <parameters-list />
    </v-col>
    <v-col
        lg="12"
        xs="12"
    >
      <form-preview />
    </v-col>
  </v-row>
</template>

<script>
import ObjectTypesList from "@/components/ObjectType/ObjectTypesList";
import ComponentsList from "@/components/ObjectType/ComponentsList";
import ParametersList from "@/components/ObjectType/ParametersList";
import FormPreview from "@/components/ObjectType/FormPreview";

export default {
  name: "ObjectTypesListPage",
  components: {FormPreview, ParametersList, ComponentsList, ObjectTypesList},
  mounted() {
    this.$store.dispatch('object_types/getObjectTypes');
    this.$store.dispatch('evaluationObjects/getEvaluationObjects');
  }
}
</script>

<style scoped>
</style>
