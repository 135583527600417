<template>
  <v-row justify="center" align="center">
    <v-col md="4">
      <v-card>
        <v-card-title>Авторизация</v-card-title>
        <v-card-text>
          <login-form/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
  name: "LoginPage",
  components: {LoginForm}
}
</script>

<style scoped>

</style>