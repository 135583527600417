<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Наименование</th>
            <th class="text-left">Адрес</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in objects"
              :key="item.id"
          >
            <td>
              <router-link :to="`/initial-data-object/${item.id}`">
                {{ item.id }}
              </router-link>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.address }}</td>
            <td>
              <v-icon
                  @click="updateObject(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-text-box-edit
              </v-icon>
              <v-icon
                  @click="deleteObject(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
        v-model="updateDialog"
        max-width="600px"
    >
      <object-update-form
          :dialog-state="updateDialog"
          @success-request="updateDialog = false"
      />
    </v-dialog>

  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import ObjectUpdateForm from "@/components/InitialData/ObjectUpdateForm";

export default {
  name: "ObjectsList",
  components: {ObjectUpdateForm},
  data() {
    return {
      updateDialog: false
    }
  },
  computed: {
    ...mapGetters({
      objects: 'initialDataObjects/getObjects'
    })
  },
  methods: {
    updateObject(id) {
      this.$store.dispatch('initialDataObjects/getInitialDataObject', id)
          .then(() => this.updateDialog = true);
    },
    deleteObject(id) {
      this.$store.dispatch('initialDataObjects/deleteInitialDataObject', id)
        .then(() => this.$store.dispatch('initialDataObjects/getInitialDataObjects'));
    }
  }
}
</script>

<style scoped>

</style>