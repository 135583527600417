<template>
  <v-card>
    <v-card-title>Создать тип конструкции</v-card-title>

    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Наименование"
            required
        ></v-text-field>

        <v-btn
            color="primary"
            block
            @click="submitForm()"
        >Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ConstructionTypeCreateForm",
  data() {
    return {
      valid: false,
      notNullRule: [
          v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null
      }
    }
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('constructionTypes/createConstructionType', this.payload)
            .then(() => {
              this.$store.dispatch('constructionTypes/getConstructionTypes');
              this.$emit("success-request");

              this.payload = {
                name: null
              }

              this.$refs.form.resetValidation()
            })
      }
    }
  }
}
</script>

<style scoped>

</style>