import Vue from "vue";
import Vuex from 'vuex'

import * as projects from '@/store/modules/Projects'
import * as object_types from '@/store/modules/ObjectTypes'
import * as components from '@/store/modules/Components'
import * as parameters from '@/store/modules/Parameters'
import * as inventory_objects from '@/store/modules/InventoryObjects'
import * as base_map from '@/store/modules/BaseMap'
import * as inventory_objects_params from '@/store/modules/InventoryObjectParams'
import * as directories from '@/store/modules/Directories'
import * as directory_items from '@/store/modules/DirectoryItems'
import * as engineers from '@/store/modules/Engineers'
import * as devices from '@/store/modules/Devices'
import * as protocols from '@/store/modules/Protocols'
import * as users from '@/store/modules/Users'
import * as auth from '@/store/modules/Auth'
import * as initialDataCategories from '@/store/modules/InitialDataCategories'
import * as initialDataObjects from '@/store/modules/InitialDataObjects'
import * as constructionTypes from '@/store/modules/ConstructionTypes'
import * as roles from '@/store/modules/Roles'
import * as evaluationObjects from '@/store/modules/EvaluationObjects'
import * as evaluationObjectDefects from '@/store/modules/EvaluationObjectDefects'
import * as evaluationObjectTechStates from '@/store/modules/EvaluationObjectTechStates'

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        projects,
        object_types,
        components,
        parameters,
        inventory_objects,
        base_map,
        inventory_objects_params,
        directories,
        directory_items,
        engineers,
        devices,
        protocols,
        users,
        auth,
        initialDataCategories,
        initialDataObjects,
        constructionTypes,
        roles,
        evaluationObjects,
        evaluationObjectDefects,
        evaluationObjectTechStates
    }
})
