export default class InventoryObjectParameter {
    constructor(params) {
        this.id = params.id;
        this.row_id = params.row_id;
        this.parameter_id = params.parameter_id;
        this.value = params.value;
        this.raw_value = params.raw_value;
        this.parameter_type = params.type;
        this.manual_mode = params.manual_mode || 0;
    }
}