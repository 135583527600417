import InventoryObjectsService from "@/services/InventoryObjectsService";

export const namespaced = true;

export const state = {
    inventoryObjects: [],
    filterParams: {},
    meta: {},
    inventoryObject: null,
    loading: false,
    objectLoading: false,
    error: null,
    page: 1
}

export const mutations = {
    SET_INVENTORY_OBJECTS(state, objects) {
        state.inventoryObjects = objects;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_INVENTORY_OBJECT(state, object) {
        state.inventoryObject = object;
    },
    SET_OBJECT_LOADING(state, loading) {
        state.objectLoading = loading;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_PAGE(state, page) {
        state.page = page;
    }
}

export const actions = {
    getInventoryObjects({commit, state}) {
        commit("SET_LOADING", true);
        InventoryObjectsService.getInventoryObjects(state.filterParams, state.page)
            .then(({objects, meta}) => {
                commit("SET_INVENTORY_OBJECTS", objects);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createInventoryObject({commit}, payload) {
        return new Promise((resolve, reject) => {
            InventoryObjectsService.createInventoryObject(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    updateInventoryObject({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            InventoryObjectsService.updateInventoryObject(id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    stateToMistakes({commit}, id) {
        return new Promise((resolve, reject) => {
            InventoryObjectsService.toMistakes(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    stateToFixed({commit}, id)
    {
        return new Promise((resolve, reject) => {
            InventoryObjectsService.toFixed(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    stateToVerified({commit}, id)
    {
        return new Promise((resolve, reject) => {
            InventoryObjectsService.toVerified(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    getInventoryObject({commit}, object_id) {
        commit("SET_OBJECT_LOADING", true);
        return new Promise((resolve, reject) => {
            InventoryObjectsService.getInventoryObject(object_id)
                .then(object => {
                    commit("SET_INVENTORY_OBJECT", object);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    }
}

export const getters = {
    getInventoryObjects: state => {
        return state.inventoryObjects;
    },
    getInventoryObject: state => {
        return state.inventoryObject;
    },

}
