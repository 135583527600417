<template>
  <v-card>
    <v-card-title class="card-title--flex">
      Дефекты
      <v-btn
        color="primary"
        rounded
        @click="isModalActive = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Наименование</th>
            <th class="text-left">Состояние</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="defect in defectsList"
            :key="defect.id"
          >
            <td>{{defect.name}}</td>
            <td>{{defect.state.name}}</td>
            <td>
              <v-icon @click="openUpdateModal(defect)"
                      class="cursor-pointer mr-2">mdi-text-box-edit</v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
      v-model="isModalActive"
      max-width="600px"
      @click:outside="clearFields"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>Добавить дефект</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="payload.name"
              :rules="notNullRule"
              label="Наименование"
              required
            ></v-text-field>

            <v-select
              v-model="payload.tech_state_id"
              :items="techStatesList"
              :rules="notNullRule"
              label="Тех. состояние"
              item-text="name"
              item-value="id"
              required
            ></v-select>

            <v-btn
              @click="checkType"
              block
              color="primary"
            >Сохранить</v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "EvaluationObjectDefectsList",
  props: {
    defectsList: {
      type: Array,
      required: true
    },
    techStatesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isModalActive: false,
      valid: true,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        name: null,
        tech_state_id: null
      },
      isFormTypeCreate: true,
      currentDefectId: 0
    }
  },
  methods: {
    openUpdateModal(defect) {
      this.currentDefectId = defect.id;
      this.payload.name = defect.name;
      this.payload.tech_state_id = defect.state.id;
      this.isFormTypeCreate = false;
      this.isModalActive = true;
    },
    checkType() {
      this.isFormTypeCreate ? this.createDefect() : this.updateDefect()
    },
    createDefect() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('evaluationObjectDefects/createDefect', {
          objectId: this.$route.params.id,
          payload: this.payload
        })
          .then(() => {
            this.isModalActive = false;
            this.clearFields()
            this.$store.dispatch('evaluationObjects/getEvaluationObject', this.$route.params.id);
          })
      }
    },
    updateDefect() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('evaluationObjectDefects/updateDefect', {
          objectId: this.$route.params.id,
          defectId: this.currentDefectId,
          payload: this.payload
        })
            .then(() => {
              this.isModalActive = false;
              this.clearFields()
              this.$store.dispatch('evaluationObjects/getEvaluationObject', this.$route.params.id);
            })
      }
    },
    clearFields() {
      this.isFormTypeCreate = true;
      this.$refs.form.resetValidation();
      this.payload = {
        name: null,
        tech_state_id: null
      }
      this.currentDefectId = 0;
    }
  }
}
</script>

<style scoped>
.card-title--flex {
  display: flex;
  justify-content: space-between;
}
</style>
