<template>
  <v-card>
    <v-card-title>Обновить проект</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.city"
            :rules="notNullRule"
            label="Город"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.executor"
            label="Исполнитель"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.basis"
            label="Основание обследования"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.customer"
            :rules="notNullRule"
            label="Заказчик"
            required
        ></v-text-field>

        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="payload.start_at"
                :rules="notNullRule"
                label="Дата начала обследования"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
              v-model="payload.start_at"
              locale="ru-ru"
              :first-day-of-week="1"
              @input="menu = false"
          ></v-date-picker>
        </v-menu>

        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UpdateProjectForm",
  props: ['projectId', 'dialogState'],
  data() {
    return {
      payload: {
        name: null,
        city: null,
        executor: null,
        basis: null,
        customer: null,
        start_at: null
      },
      menu: false,
      valid: false,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  computed: {
    ...mapGetters({
      project: 'projects/getProject'
    })
  },
  watch: {
    dialogState: function (state) {
      if (state) {
        this.fillFields();
      }
    }
  },
  mounted() {
    this.fillFields();
  },
  methods: {
    fillFields() {
      const project = this.project(this.projectId);
      this.payload.name = project.name;
      this.payload.city = project.city;
      this.payload.executor = project.executor;
      this.payload.basis = project.basis;
      this.payload.customer = project.customer;
      this.payload.start_at = project.start_at;
    },
    submitForm() {
      this.$store.dispatch('projects/updateProject', {
        projectId: this.projectId,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('projects/getProjects');
            this.$emit('success-request');

            this.payload = {
              name: null,
              city: null,
              executor: null,
              basis: null,
              customer: null,
              start_at: null
            }

            this.$refs.form.resetValidation();
          })
    }
  }
}
</script>

<style scoped>

</style>