<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">ID Объекта</th>
          <th class="text-left">Наименование Объекта</th>
          <th class="text-left">Адрес Объекта</th>
          <th class="text-left">дата обследования</th>
          <th class="text-left">Тип объекта</th>
          <th class="text-left">Инженер</th>
        </tr>
      </thead>
      <tbody>
        <tr
            v-for="item in items"
            :key="item.object_id"
        >
          <td><a :href="'/inventory-object/'+item.object_id" target="_blank">{{ item.object_id }}</a></td>
          <td>{{ item.object_name }}</td>
          <td>{{ item.object_address }}</td>
          <td>{{ item.object_event_at }}</td>
          <td>{{ item.object_type_name }}</td>
          <td>{{ item.user_name }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "EngineerDetailResultsList",
  props: [
    'items'
  ],
}
</script>

<style scoped>

</style>
