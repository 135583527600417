<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>{{ object.name }}</v-card-title>
        <v-card-text>{{ object.address }}</v-card-text>
      </v-card>
    </v-col>

    <v-col md="12">
      <object-values-list />
    </v-col>

    <v-dialog
        v-model="createDialog"
        max-width="600px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
            @click="createDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <object-value-create-form @success-request="createDialog = false" />
    </v-dialog>

  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import ObjectValuesList from "@/components/InitialData/ObjectValuesList";
import ObjectValueCreateForm from "@/components/InitialData/ObjectValueCreateForm";

export default {
  name: "ObjectPage",
  components: {ObjectValueCreateForm, ObjectValuesList},
  data() {
    return {
      createDialog: false
    }
  },
  computed: {
    ...mapGetters({
      object: 'initialDataObjects/getObject'
    })
  },
  mounted() {
    this.$store.dispatch('initialDataCategories/getInitialDataCategories');
    this.$store.dispatch('initialDataObjects/getInitialDataObject', this.$route.params.id);
    this.$store.dispatch('initialDataObjects/getInitialDataObjectValues', this.$route.params.id);
  }
}
</script>

<style scoped>

</style>