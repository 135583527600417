<template>
  <base-map :center="$store.getters['base_map/getCenter']"
            :zoom="$store.getters['base_map/getZoom']"
            :set-marker-by-click="true" style="height: 100%" :initial-coords="initialCoords"/>
</template>

<script>
import BaseMap from "@/layout/BaseMap";
export default {
  name: "CreateInventoryObjectMap",
  components: {BaseMap},
  props: {
    initialCoords: [],
  }
}
</script>

<style scoped>

</style>
