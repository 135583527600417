<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import MainTemplate from "@/layout/MainTemplate";
import LoginTemplate from "@/layout/LoginTemplate";

export default {
  name: 'App',
  components: {MainTemplate, LoginTemplate},
  computed: {
    layout() {
      return (this.$route.meta?.layout || 'Login') + '-Template'
    }
  }
}
</script>
