<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Наименование</th>
            <th class="text-left">Производитель</th>
            <th class="text-left">Серийный номер</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in devices"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.vendor }}</td>
            <td>{{ item.serial_number }}</td>
            <td>
              <v-icon
                  @click="updateDevice(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-text-box-edit
              </v-icon>
              <v-icon
                  @click="deleteDevice(item.id)"
                  class="cursor-pointer"
              >mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DevicesList",
  computed: {
    ...mapGetters({
      devices: 'devices/getDevices'
    })
  },
  methods: {
    updateDevice(device_id) {
      this.$store.dispatch('devices/getDevice', device_id)
          .then(() => {
            this.$emit('update-device');
            this.$emit('open-dialog');
          })
    },
    deleteDevice(device_id) {
      this.$store.dispatch('devices/deleteDevice', device_id)
          .then(() => this.$store.dispatch('devices/getDevices'));
    }
  }
}
</script>

<style scoped>

</style>