export class Engineer {
    constructor(params) {
        this.id = params.id;
        this.full_name = params.full_name;
        this.short_name = params.short_name;
        this.position = params.position;
        this.created_at = params.created_at;
        this.devices = params.devices
    }
}
