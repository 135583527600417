<template>
  <div>
    <directory-form />
    <directories-list />
  </div>
</template>

<script>
import DirectoryForm from "@/components/Drectory/DirectoryForm";
import DirectoriesList from "@/components/Drectory/DirectoriesList";
export default {
  name: "DirectoriesListPage",
  components: {DirectoriesList, DirectoryForm},
  mounted() {
    this.$store.dispatch('directories/getDirectories');
  }
}
</script>

<style scoped>

</style>