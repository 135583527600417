<template>
  <v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">ФИО</th>
            <th class="text-left">Фамилия инициалы</th>
            <th class="text-left">Должность</th>
            <th class="text-left">Дата создания</th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in engineers"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.full_name }}</td>
            <td>{{ item.short_name }}</td>
            <td>{{ item.position }}</td>
            <td>{{ item.created_at }}</td>
            <td>
              <v-icon
                  @click="updateEngineer(item.id)"
                  class="cursor-pointer mr-2"
              >mdi-text-box-edit
              </v-icon>
              <v-icon
                  @click="deleteEngineer(item.id)"
                  class="cursor-pointer"
              >mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EngineersList",
  computed: {
    ...mapGetters({
      engineers: 'engineers/getEngineers'
    })
  },
  methods: {
    updateEngineer(id) {
      this.$store.dispatch('engineers/getEngineer', id)
          .then(() => {
            this.$emit('update-engineer');
            this.$emit('open-dialog');
          })
    },
    deleteEngineer(id) {
      this.$store.dispatch('engineers/deleteEngineer', id)
          .then(() => this.$store.dispatch('engineers/getEngineers'))
    }
  }
}
</script>

<style scoped>

</style>