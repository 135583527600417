<template>
  <div class="text-center">
    <v-chip class="ma-2" color="primary" v-if="id == 1">
      {{name}}
    </v-chip>

    <v-chip class="ma-2" color="secondary" v-if="id == 3">
      {{name}}
    </v-chip>

    <v-chip class="ma-2" color="red" text-color="white" v-if="id == 2">
      {{name}}
    </v-chip>

    <v-chip class="ma-2" color="green" text-color="white" v-if="id == 4">
     {{name}}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "ObjectState",
  props: [
      'id',
      'name'
  ]
}
</script>

<style scoped>

</style>
