export default class InitialDataValue {
    constructor(params) {
        this.id = params.id;
        this.category = params.category;
        this.name = params.name;
        this.availability = params.availability;
        this.carrier = params.carrier;
        this.uploaded = params.uploaded;
        this.remark = params.remark;
    }

    static getAvailabilities() {
        return [
            { value: 0, text: 'Частично' },
            { value: 1, text: 'Да' },
            { value: 2, text: 'Нет' }
        ]
    }

    static getCarriers() {
        return [
            { value: 1, text: 'Бумажный носитель' },
            { value: 2, text: 'Электронный носитель' }
        ]
    }

    static getUploadState() {
        return [
            { value: 1, text: 'Загружено' },
            { value: 2, text: 'Не загружено' }
        ]
    }
}