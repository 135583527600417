<template>
  <v-card>
    <v-card-title v-if="formType === 'create'">Добавить устройство</v-card-title>
    <v-card-title v-else>Изменить устройство</v-card-title>

    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >

        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Наименование"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.vendor"
            :rules="notNullRule"
            label="Производитель"
            required
        ></v-text-field>

        <v-text-field
            v-model="payload.serial_number"
            :rules="notNullRule"
            label="Серийный номер"
            required
        ></v-text-field>

        <v-btn
            v-if="formType === 'create'"
            color="primary"
            block
            @click="createDevice()"
        >Добавить
        </v-btn>

        <v-btn
            v-if="formType === 'update'"
            color="primary"
            block
            @click="updateDevice()"
        >Изменить
        </v-btn>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DevicesForm",
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      payload: {
        name: null,
        vendor: null,
        serial_number: null
      },
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  watch: {
    formType: function (value) {
      if (value === 'create') {
        this.payload = {
          name: null,
          vendor: null,
          serial_number: null
        }
        this.$refs.form.resetValidation();
      } else if (value === 'update') {
        this.setFields();
      }
    }
  },
  mounted() {
    this.setFields();
  },
  methods: {
    setFields() {
      const device = this.$store.getters['devices/getDevice'];
      this.payload.name = device.name;
      this.payload.vendor = device.vendor;
      this.payload.serial_number = device.serial_number;
    },
    createDevice() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('devices/createDevice', this.payload)
            .then(() => {
              this.$store.dispatch('devices/getDevices');
              this.payload = {
                name: null,
                vendor: null,
                serial_number: null
              }

              this.$refs.form.resetValidation();
              this.$emit('close-dialog');
            })
      }
    },
    updateDevice() {
      const device = this.$store.getters['devices/getDevice'];
      if (this.$refs.form.validate()) {
        this.$store.dispatch('devices/updateDevice', {
          device_id: device.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('devices/getDevices');

              this.$refs.form.resetValidation();
              this.$emit('close-dialog');
            })
      }
    }
  }
}
</script>

<style scoped>

</style>