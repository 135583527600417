import ObjectTypesService from "@/services/ObjectTypesService";

export const namespaced = true;

export const state = {
    object_types: [],
    object_type: null,
    loading: false,
    object_type_loading: false,
    error: null,
    active_object_type_id: null
}

export const mutations = {
    SET_OBJECT_TYPES(state, types) {
        state.object_types = types;
    },
    SET_OBJECT_TYPE(state, type) {
        state.object_type = type;
    },
    SET_OBJECT_TYPE_LOADING(state, loading) {
        state.object_type_loading = loading;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_ACTIVE_OBJECT_TYPE_ID(state, id) {
        state.active_object_type_id = id;
    }
}

export const actions = {
    getObjectTypes({commit}) {
        commit("SET_LOADING", true);
        ObjectTypesService.getObjectTypes()
            .then(objectTypes => commit("SET_OBJECT_TYPES", objectTypes))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createObjectType({commit}, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ObjectTypesService.createObjectType(payload)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    },
    updateObjectType({commit}, {typeId, payload}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ObjectTypesService.updateObjectType({typeId, payload})
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    },
    activateObjectType({commit}, typeId) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ObjectTypesService.activateObjectType(typeId)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    },
    deactivateObjectType({commit}, typeId) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ObjectTypesService.deactivateObjectType(typeId)
                .then(() => resolve())
                .catch(error => reject(error))
                .finally(() => commit("SET_LOADING", false));
        })
    },
    getObjectType({commit}, id) {
        commit("SET_OBJECT_TYPE_LOADING", true);
        ObjectTypesService.getObjectType(id)
            .then(object_type => commit("SET_OBJECT_TYPE", object_type))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_OBJECT_TYPE_LOADING", false));
    },
    uploadTemplate({commit}, {object_type_id, template}) {
        return new Promise((resolve, reject) => {
            ObjectTypesService.uploadTemplate({object_type_id, template})
                .then(() => resolve())
                .catch(error => {
                    reject(error);
                    commit("SET_ERROR", error);
                })
        })
    }
}

export const getters = {
    getObjectTypes: state => {
        return state.object_types;
    },
    getObjectTypeFromList: state => id => {
        return state.object_types.find(item => item.id === parseInt(id));
    },
    getObjectType: state => {
        return state.object_type;
    },
    getObjectTypeId: state => {
        return state.object_type.id
    },
    getActiveObjectTypeId: state => {
        return state.active_object_type_id;
    },
    getObjectTypeName: state => id => {
        const objectType = state.object_types.find(objectType => objectType.id === id);
        if (objectType) return objectType.name;
    },
}
