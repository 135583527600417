import * as API from './API'
import InitialDataObject from "@/models/InitialDataObject";
import InitialDataValue from "@/models/InitialDataValue";

export default {
    fetchInitialDataObjects() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/initial-data/objects`)
                .then(response => resolve(response.data.data.map(item => new InitialDataObject(item))))
                .catch(error => reject(error));
        })
    },
    fetchInitialDataObject(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/initial-data/objects/${id}`)
                .then(response => resolve(new InitialDataObject(response.data.data)))
                .catch(error => reject(error));
        })
    },
    createInitialDataObject(payload) {
        return API.apiClient.post(`/api/initial-data/objects`, payload);
    },
    updateInitialDataObject(id, payload) {
        return API.apiClient.patch(`/api/initial-data/objects/${id}`, payload);
    },
    deleteInitialDataObject(id) {
        return API.apiClient.delete(`/api/initial-data/objects/${id}`);
    },
    fetchInitialDataObjectValues(object_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/initial-data/objects/${object_id}/values`)
                .then(response => resolve(response.data.data.map(item => new InitialDataValue(item))))
                .catch(error => reject(error));
        })
    },
    fetchInitialDataObjectValue(object_id, value_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/initial-data/objects/${object_id}/values/${value_id}`)
                .then(response => resolve(new InitialDataValue(response.data.data)))
                .catch(error => reject(error));
        })
    },
    createInitialDataObjectValue(object_id, payload) {
        return API.apiClient.post(`/api/initial-data/objects/${object_id}/values`, payload);
    },
    updateInitialDataObjectValue(object_id, value_id, payload) {
        return API.apiClient.patch(`/api/initial-data/objects/${object_id}/values/${value_id}`, payload);
    },
    deleteInitialDataObjectValue(object_id, value_id) {
        return API.apiClient.delete(`/api/initial-data/objects/${object_id}/values/${value_id}`);
    }
}