<template>
  <v-card>
    <v-card-title>Создать компонент</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.params.name"
            :rules="nameRules"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.params.component_code"
            :rules="componentCodeRules"
            label="Cимвольный код"
            required
        ></v-text-field>
        <v-checkbox
            v-model="payload.params.is_form"
            label="Является формой">
        </v-checkbox>
        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CreateComponentForm",
  data() {
    return {
      payload: {
        params: {
          name: null,
          component_code: null,
          is_form: false
        }
      },
      nameRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      componentCodeRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      valid: true
    }
  },
  computed: {
    ...mapGetters({
      object_type_id: 'object_types/getActiveObjectTypeId'
    })
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.payload.object_type_id = this.object_type_id;
        this.$store.dispatch('components/createComponent', this.payload)
            .then(() => {
              this.$store.dispatch('components/getComponents', this.object_type_id);
              this.$emit('success-request');

              this.payload.params = {
                name: null,
                component_code: null,
                is_form: false
              }

              this.$refs.form.resetValidation()
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
