export default class User {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.email = params.email;
        this.banned_at = params.banned_at;
        this.roles = params.roles;
    }

    isHasRole(role) {
        if (role == null) {
            return true;
        }
        return this.roles.includes(role);
    }

    isBanned() {
        return this.banned_at != null;
    }
}
