import DefectsService from "@/services/DefectsService";

export const namespaced = true;

export const state = {
    defectValues: [],
    error: null
}

export const mutations = {
    SET_DEFECT_VALUES(state, values) {
        state.defectValues = values;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    createDefect(context, {objectId, payload}) {
        return new Promise((resolve, reject) => {
            DefectsService.createDefect(objectId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateDefect(context, {objectId, defectId, payload}) {
        return new Promise((resolve, reject) => {
            DefectsService.updateDefect(objectId, defectId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    getDefectValues({commit}) {
        DefectsService.fetchDefectValues()
            .then(defectValues => commit("SET_DEFECT_VALUES", defectValues))
            .catch(error => commit("SET_ERROR", error))
    }
}
