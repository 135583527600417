<template>
  <v-row>
    <v-col
        xl="6"
        xs="12"
    >
      <create-object-form />
    </v-col>
    <v-col
        xl="6"
        xs="12"
    >
      <create-object-map />
    </v-col>
  </v-row>
</template>

<script>
import CreateObjectForm from "@/components/InventoryObject/CreateObjectForm";
import CreateObjectMap from "@/components/InventoryObject/CreateObjectMap";
export default {
  name: "CreateInventoryObjectPage",
  components: {CreateObjectMap, CreateObjectForm},
  mounted() {
    this.$store.dispatch('engineers/getEngineers');
  }
}
</script>

<style scoped>

</style>