import * as API from './API';
import DefectValue from "@/models/TCCalculator/DefectValue";

export default {
    createDefect(objectId, payload) {
        return API.apiClient.post(`/api/catalogs/tcc/eval-objects/${objectId}/defects`, payload);
    },
    updateDefect(objectId, defectId, payload) {
        return API.apiClient.patch(`/api/catalogs/tcc/eval-objects/${objectId}/defects/${defectId}`, payload);
    },
    fetchDefectValues() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/tcc/defect-values`)
                .then(response => resolve(response.data.data.map(item => new DefectValue(item))))
                .catch(error => reject(error))
        })
    }
}
