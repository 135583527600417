<template>
  <v-card>
    <v-card-title>Создать тип объекта</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="payload.name"
            :rules="nameRules"
            label="Название"
            required
        ></v-text-field>
        <v-text-field
            v-model="payload.description"
            :rules="descriptionRules"
            label="Описание"
            required
        ></v-text-field>
        <v-btn
            block
            color="primary"
            @click="submitForm"
        >
          Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CreateObjectTypeForm",
  data() {
    return {
      payload: {
        name: null,
        description: null
      },
      valid: false,
      nameRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      descriptionRules: [
        v => !!v || 'Поле обязательно для заполнения'
      ]
    }
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('object_types/createObjectType', this.payload)
            .then(() => {
              this.$store.dispatch('object_types/getObjectTypes');
              this.$emit('success-request');

              this.payload = {
                name: null,
                description: null
              }

              this.$refs.form.resetValidation()
            })
      }
    }
  }
}
</script>

<style scoped>

</style>