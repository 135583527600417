export default class InventoryObject {
    constructor(params) {
        this.id = params.id;
        this.project_id = params.project_id;
        this.object_type_id = params.object_type_id;
        this.name = params.name;
        this.address = params.address;
        this.build_year = params.build_year;
        this.commissioning_year = params.commissioning_year;
        this.perimeter = params.perimeter;
        this.area = params.area;
        this.height = params.height;
        this.volume = params.volume;
        this.wear_percent = params.wear_percent;
        this.coordinates = params.coordinates;
        this.event_at = params.event_at;
        this.engineers = params.engineers;
        this.construction_type = params.construction_type;
        this.subscriber_base_number = params.subscriber_base_number;
        this.state_id = params.state.id;
        this.state_name = params.state.name;
        this.devices = params.devices;
        this.district = params.district;
    }
}
