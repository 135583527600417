import * as API from '@/services/API'
import {Protocol} from "@/models/Protocol";

export default {
    generateProtocol(object_id, is_excel= false) {
        return API.apiClient.post('/api/protocols', {object_id, is_excel});
    },
    deleteProtocol(protocol_id) {
        return API.apiClient.delete('/api/protocols/'+protocol_id);
    },
    getProtocols(term) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/api/protocols?term=' + term)
                .then(response => resolve(response.data.data.map(item => new Protocol(item))))
                .catch(error => reject(error));
        })
    }
}
