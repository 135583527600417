import EvaluationObjectsService from "@/services/EvaluationObjectsService";

export const namespaced = true;

export const state = {
    objectsList: [],
    object: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_OBJECTS_LIST(state, objects) {
        state.objectsList = objects;
    },
    SET_OBJECT(state, object) {
        state.object = object;
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getEvaluationObjects({commit}) {
        commit("SET_LOADING", true);
        EvaluationObjectsService.fetchEvaluationObjectsList()
            .then(evaluationsObjects => commit("SET_OBJECTS_LIST", evaluationsObjects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getEvaluationObject({commit}, objectId) {
        return new Promise((resolve, reject) => {
            EvaluationObjectsService.fetchEvaluationObject(objectId)
                .then(evalObject => {
                    commit("SET_OBJECT", evalObject);
                    resolve(evalObject);
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
        })
    },
    createEvaluationObject(context, {categoryId, payload}) {
        return new Promise((resolve, reject) => {
            EvaluationObjectsService.createEvaluationObject(categoryId, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    },
    updateEvaluationObject(context, {id, payload}) {
        return new Promise((resolve, reject) => {
            EvaluationObjectsService.updateEvaluationObject(id, payload)
                .then(() => resolve())
                .catch(error => reject(error))
        })
    }
}
