import * as API from './API';
import ConstructionType from "@/models/InventoryObject/ConstructionType";

export default {
    fetchConstructionTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/construction-types`)
                .then(response => resolve(response.data.data.map(item => new ConstructionType(item))))
                .catch(error => reject(error));
        })
    },
    fetchConstructionType(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/construction-types/${id}`)
                .then(response => resolve(new ConstructionType(response.data.data)))
                .catch(error => reject(error));
        })
    },
    createConstructionType(payload) {
        return API.apiClient.post(`/api/catalogs/construction-types`, payload);
    },
    updateConstructionType(id, payload) {
        return API.apiClient.patch(`/api/catalogs/construction-types/${id}`, payload);
    },
    deleteConstructionType(id) {
        return API.apiClient.delete(`/api/catalogs/construction-types/${id}`);
    }
}
