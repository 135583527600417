export default class Project {
    constructor(params) {
        this.id = params.id;
        this.city = params.city;
        this.name = params.name;
        this.executor = params.executor;
        this.basis = params.basis;
        this.customer = params.customer;
        this.start_at = params.start_at;
        this.finish_at = params.finish_at;
        this.created_at = params.created_at;
    }
}