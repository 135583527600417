<template>
  <v-card>
    <v-card-title>Элементы справочника</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">ID</th>
            <th v-if="directory($route.params.id).type === 1"
                class="text-left">Наименование
            </th>
            <th v-if="directory($route.params.id).type === 2"
                class="text-left">Порядковый номер
            </th>
            <th v-if="directory($route.params.id).type === 2"
                class="text-left">Изображение
            </th>
            <th class="text-left">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="item in directory_items"
              :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td v-if="directory($route.params.id).type === 1">{{ item.value }}</td>
            <td v-if="directory($route.params.id).type === 2">{{ item.number }}</td>
            <td v-if="directory($route.params.id).type === 2">
              <span
                  @click="showImage(item.img_url)"
                  class="show-img-btn"
              >Показать</span>
            </td>
            <td>
              <v-icon @click="openDirectoryItemModal(item.id)"
                      class="cursor-pointer mr-2">mdi-text-box-edit
              </v-icon>
              <v-icon @click="deleteDirectoryItem(item.id)"
                      class="cursor-pointer">mdi-delete
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog
        v-model="dialog"
        max-width="600px"
    >
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-card>
          <v-card-title>Изменить элемент справочника</v-card-title>
          <v-card-text>
            <v-text-field
                v-if="directory($route.params.id).type === 1"
                v-model="value"
                :rules="notNullRule"
                label="Название элемента справочника"
                class="mr-3"
                required
            ></v-text-field>

            <v-text-field
                v-if="directory($route.params.id).type === 2"
                v-model="number"
                :rules="notNullRule"
                label="Порядковый номер"
                required
            ></v-text-field>

            <v-file-input
                v-if="directory($route.params.id).type === 2"
                v-model="img"
                :rules="notNullRule"
                label="Фото"
                required
            ></v-file-input>
            <v-btn
                @click="updateDirectoryItem"
                block
                color="primary"
            >Сохранить
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import VueViewer from 'v-viewer'
import Vue from 'vue'

Vue.use(VueViewer);

export default {
  name: "DirectoryItemsList",
  data() {
    return {
      fields: [
        {key: 'id', label: 'id'},
        {key: 'value', label: 'Значение'},
        {key: 'controls', label: 'Управление'}
      ],
      currentDirectoryItemId: null,
      value: null,
      number: null,
      img: null,
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      dialog: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters({
      directory_items: 'directory_items/getDirectoryItems',
      directory_item: 'directory_items/getDirectoryItem',
      directory: 'directories/getDirectoryById'
    })
  },
  methods: {
    openDirectoryItemModal(item_id) {
      this.currentDirectoryItemId = item_id;
      if (this.directory(this.$route.params.id).type === 1) this.value = this.directory_item(item_id).value;
      else this.number = this.directory_item(item_id).number;

      this.dialog = true;
    },
    updateDirectoryItem() {
      if (this.$refs.form.validate()) {
        const directoryId = this.$route.params.id;
        let payload;

        if (this.directory(directoryId).type === 1) {
          payload = {
            value: this.value
          }
        } else {
          payload = {
            number: this.number,
            img: this.img
          }
        }

        this.$store.dispatch('directory_items/updateDirectoryItem', {
          directory_id: directoryId,
          item_id: this.currentDirectoryItemId,
          payload: payload
        })
            .then(() => {
              this.$store.dispatch('directory_items/getDirectoryItems', directoryId);
              this.dialog = false;
              this.value = null;
              this.number = null;
              this.img = null;
              this.$refs.form.resetValidation();
            })
      }
    },
    showImage(image) {
      this.$viewerApi({
        images: [image],
        options: {
          navbar: false,
          title: false,
          toolbar: false
        }
      })
    },
    deleteDirectoryItem(item_id) {
      this.$store.dispatch('directory_items/deleteDirectoryItem', {
        directory_id: this.$route.params.id,
        item_id: item_id,
      })
          .then(() => {
            this.$store.dispatch('directory_items/getDirectoryItems', this.$route.params.id);
          })
    }
  }
}
</script>

<style scoped>
.show-img-btn {
  font-weight: 700;
  cursor: pointer;
}
</style>