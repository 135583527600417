import * as API from '@/services/API'
import ObjectType from "@/models/InventoryObject/ObjectType";

export default {
    getObjectTypes() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('/api/catalogs/object-types')
                .then(response => {
                    resolve(response.data.data.map(item => new ObjectType(item)))
                })
                .catch(error => reject(error))
        })
    },
    createObjectType(payload) {
        return API.apiClient.post('/api/catalogs/object-types', payload);
    },
    updateObjectType({typeId, payload}) {
        return API.apiClient.patch(`/api/catalogs/object-types/${typeId}`, payload);
    },
    activateObjectType(typeId) {
        return API.apiClient.post(`/api/catalogs/object-types/${typeId}/activate`);
    },
    deactivateObjectType(typeId) {
        return API.apiClient.post(`/api/catalogs/object-types/${typeId}/deactivate`);
    },
    getObjectType(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/catalogs/object-types/${id}`)
                .then(response => {
                    resolve(new ObjectType(response.data.data))
                })
                .catch(error => reject(error));
        })
    },
    uploadTemplate({object_type_id, template}) {
        return API.apiClient.post(`/api/catalogs/object-types/${object_type_id}/templates`,
            {template},
            {
                'headers': {
                    'Content-Type': 'multipart/form-data'
                }
            })
    }
}
