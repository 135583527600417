import * as API from './API'
import Role from "@/models/Role";

export default {
    fetchRoles() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/users/roles/`)
                .then(response => resolve(response.data.map(item => new Role(item))))
                .catch(error => reject(error))
        })
    },
}
