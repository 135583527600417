<template>
  <div class="photo-defects">
    <div class="photo-defects__label">{{ formLabel }}</div>
    <div class="photo-defects__form">
      <v-file-input
        v-model="formData.photo"
        label="Выберите фото"
        counter
        show-size
      />

      <v-item-group>
        <v-item v-if="formData.manualMode === 0">
          <v-select
            v-model="formData.defect"
            label="Дефект"
            :items="parameterValues"
            item-text="name"
            item-value="id"
            hide-details
          ></v-select>
        </v-item>
        <v-item v-else>
          <v-text-field
            v-model="formData.defect"
            label="Дефект"
            hide-details
          ></v-text-field>
        </v-item>
        <v-item>
          <v-checkbox
            v-model="formData.manualMode"
            label="Ручной ввод"
            :true-value="1"
            :false-value="0"
            class="mt-1"
            hide-details
          ></v-checkbox>
        </v-item>
      </v-item-group>

      <v-btn
        color="primary"
        dark
        fab
        @click="addPhotoDefect()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <div class="photo-defects__list">
      <div
        v-for="(defect, index) in this.photoDefectsList"
        :key="index"
        class="photo-defects__item">
        <div class="photo-defects__photo-preview">
          <img
            v-if="typeof defect.photo === 'string'"
            :src="defect.photo"
            alt="preview">
          <img
            v-else
            :src="createPhotoPreviewUrl(defect.photo)"
            alt="preview">
        </div>
        <div class="photo-defects__defect-text">
          <span v-if="defect.manualMode === 0">{{getDefectName(defect.defect)}}</span>
          <span v-else>{{defect.defect}}</span>
        </div>
        <div class="photo-defects__list-btn">
          <v-btn
            color="danger"
            dark
            fab
            width="30px"
            height="30px"
            @click="removePhotoDefect(index, defect.photo)"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createPhotoPreviewUrl} from "@/utils/helpers";
import {emitter} from "@/utils/emmiter";

export default {
  name: "PhotoDefects",
  props: {
    formLabel: {
      type: String,
      required: true
    },
    parameterValues: {
      type: Array,
      required: true
    },
    parameterValue: {},
    parameterRawValue: {},
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      photoDefectsList: [],
      formData: {
        photo: null,
        defect: null,
        manualMode: 0
      }
    }
  },
  watch: {
    photoDefectsList: function (value) {
      this.$emit('update:defectsLit', value);
    },
    parameterValue: function (value) {
      if (typeof value === 'string') {
        this.setPhotoDefects(value);
      }
    }
  },
  mounted() {
    if (typeof this.parameterValue === 'string') {
      this.setPhotoDefects(this.parameterValue)
    }

    emitter.on('parameters-form-submit', () => {
      this.photoDefectsList = [];
    })
  },
  methods: {
    createPhotoPreviewUrl,
    addPhotoDefect() {
      this.photoDefectsList.push(this.formData);
      this.formData = {
        photo: null,
        defect: null,
        manualMode: 0
      }
    },
    removePhotoDefect(index, photo) {
      this.photoDefectsList.splice(index, 1);

      if (this.editMode) {
        const parameterRaw = this.parameterRawValue;
        const rawValueArray = parameterRaw.split(';');
        const rawValue = rawValueArray.find(item => {
          const [fileUrl] = item.split('|');
          return photo.includes(fileUrl)
        });
        const [deletedFileUrl] = rawValue.split('|');
        this.$emit('update:deletedValues', deletedFileUrl);
      }
    },
    getDefectName(id) {
      const defect = this.parameterValues.find(item => item.id === id);
      return defect ? defect.name : 'Имя не найдено'
    },
    setPhotoDefects(value) {
      const rawValue = value.split(';');
      this.photoDefectsList = rawValue.map(item => {
        const [photo, defect, manualMode] = item.split('|');
        return {
          photo,
          defect,
          manualMode,
          old: true
        }
      })
    }
  }
}
</script>

<style scoped>
.photo-defects {
}

.photo-defects__label {
  font-size: 16px;
  margin-bottom: 10px;
}

.photo-defects__form {
  display: grid;
  grid-template-columns: 1fr 1fr 60px;
  gap: 20px;
}

.photo-defects__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.photo-defects__item {
  display: grid;
  grid-template-columns: 100px 200px 60px;
  gap: 20px;
  align-items: center;
}

.photo-defects__photo-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.photo-defects__defect-text {
  font-size: 16px;
}

.photo-defects__list-btn {
}
</style>
