<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">ID</th>
          <th class="text-left">Наименование</th>
          <th class="text-left">Управление</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="object in objectsList"
          :key="object.id"
        >
          <td>
            <router-link :to="`/evaluation-object/${object.id}`">
              {{ object.id }}
            </router-link>
          </td>
          <td>{{ object.name }}</td>
          <td>
            <v-icon @click="openObjectModal(object)"
                    class="cursor-pointer mr-2">mdi-text-box-edit
            </v-icon>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title>Изменить объект оценки</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="payload.name"
              :rules="notNullRule"
              label="Наименование"
              required
            ></v-text-field>
            <v-btn
              @click="updateObject"
              block
              color="primary"
            >Сохранить
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EvaluationObjectsList",
  props: {
    objectsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      dialog: false,
      valid: true,
      payload: {
        name: null
      },
      currentObjectId: 0
    }
  },
  methods: {
    openObjectModal(object) {
      this.dialog = true;
      this.currentObjectId = object.id;
      this.payload.name = object.name;
    },
    updateObject() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('evaluationObjects/updateEvaluationObject', {
          id: this.currentObjectId,
          payload: this.payload
        })
          .then(() => {
            this.dialog = false;
            this.payload = {
              name: null
            }
            this.currentObjectId = 0;

            this.$store.dispatch('evaluationObjectCategories/getEvaluationCategory', this.$route.params.id);
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
