<template>
  <v-card class="mb-3">
    <v-card-title>Создать справочник</v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <div class="create-directory-form">

          <v-text-field
              v-model="payload.name"
              :rules="notNullRule"
              label="Название"
              class="mr-3"
              required
          ></v-text-field>

          <v-select
              v-model="payload.type"
              :rules="notNullRule"
              label="Тип справочника"
              :items="select_options"
              required
          ></v-select>

          <v-btn
              color="primary"
              @click="submitForm"
          >Добавить</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DirectoryForm",
  data() {
    return {
      payload: {
        name: null,
        type: null
      },
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      valid: true,
      select_options: [
        {value: 1, text: 'Текстовый'},
        {value: 2, text: 'Фото'}
      ]
    }
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('directories/createDirectory', this.payload)
            .then(() => {
              this.$store.dispatch('directories/getDirectories');
              this.payload.name = null;
              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>
.create-directory-form {
  display: flex;
  align-items: center;
}
</style>