<template>
  <v-card>
    <v-card-title>Изменить пароль</v-card-title>

    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >
        <v-text-field
            v-model="payload.password"
            :append-icon="textType ? 'mdi-eye' : 'mdi-eye-off'"
            :type="textType ? 'text' : 'password'"
            :rules="passwordRules"
            label="Пароль"
            required
            @click:append="textType = !textType"
        ></v-text-field>

        <v-text-field
            v-model="payload.password_confirmation"
            :append-icon="textType ? 'mdi-eye' : 'mdi-eye-off'"
            :type="textType ? 'text' : 'password'"
            @click:append="textType = !textType"
            :rules="[
                [v => !!v || 'Поле обязательно для заполнения'],
                (this.payload.password === this.payload.password_confirmation) || 'Пароли должны совпадать']"
            label="Повторите пароль"
            required
        ></v-text-field>

        <v-btn
            color="primary"
            block
            @click="submitForm()"
        >Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserResetPasswordForm",
  data() {
    return {
      valid: false,
      payload: {
        password: null,
        password_confirmation: null
      },
      passwordRules: [
        v => !!v || 'Поле обязательно для заполнения',
        (v) => (v && /[A-Z]/.test(v)) || 'Пароль должен содержать заглавную букву',
        (v) => (v && /[a-z]/.test(v)) || 'Пароль должен содержать строчную букву',
        (v) => (v && /\d/.test(v)) || 'Пароль должен содержать цифру',
        (v) => (v && v.length > 6 ) || 'Пароль должен содержать минимум 6 символов'
      ],
      textType: false
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'users/getUser'
    })
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('users/resetPassword', {
          userId: this.activeUser.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('users/getUsers');
              this.$emit('success-request');

              this.payload = {
                password: null,
                password_confirmation: null
              };
              this.textType = false;

              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>

</style>