import * as API from './API'
import InitialDataCategory from "@/models/InitialDataCategory";

export default {
    fetchInitialDataCategories() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/initial-data/categories`)
                .then(response => resolve(response.data.data.map(item => new InitialDataCategory(item))))
                .catch(error => reject(error))
        })
    },
    fetchInitialDataCategory(id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/api/initial-data/categories/${id}`)
                .then(response => resolve(new InitialDataCategory(response.data.data)))
                .catch(error => reject(error))
        })
    },
    createInitialDataCategory(payload) {
        return API.apiClient.post(`/api/initial-data/categories`, payload);
    },
    updateInitialDataCategory(id, payload) {
        return API.apiClient.patch(`/api/initial-data/categories/${id}`, payload);
    },
    deleteInitialDataCategory(id) {
        return API.apiClient.delete(`/api/initial-data/categories/${id}`);
    }
}