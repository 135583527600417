import ConstructionTypesService from "@/services/ConstructionTypesService";

export const namespaced = true;

export const state = {
    constructionTypes: [],
    constructionType: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_CONSTRUCTION_TYPES(state, types) {
        state.constructionTypes = types;
    },
    SET_CONSTRUCTION_TYPE(state, type) {
        state.constructionType = type;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getConstructionTypes({commit}) {
        commit("SET_LOADING", true);
        ConstructionTypesService.fetchConstructionTypes()
            .then(types => commit("SET_CONSTRUCTION_TYPES", types))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getConstructionType({commit}, id) {
        return new Promise((resolve, reject) => {
            ConstructionTypesService.fetchConstructionType(id)
                .then(type => {
                    commit("SET_CONSTRUCTION_TYPE", type);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createConstructionType({commit}, payload) {
        return new Promise((resolve, reject) => {
            ConstructionTypesService.createConstructionType(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    updateConstructionType({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            ConstructionTypesService.updateConstructionType(id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deleteConstructionType({commit}, id) {
        return new Promise((resolve, reject) => {
            ConstructionTypesService.deleteConstructionType(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getConstructionTypes: state => {
        return state.constructionTypes;
    },
    getConstructionType: state => {
        return state.constructionType;
    }
}