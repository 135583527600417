import InitialDataObjectsService from "@/services/InitialDataObjectsService";

export const namespaced = true;

export const state = {
    objects: [],
    object: null,
    objectValues: [],
    objectValue: null,
    objectsLoading: false,
    objectsError: null,
    objectValuesLoading: false,
    objectValueError: null
}

export const mutations = {
    SET_OBJECTS(state, objects) {
        state.objects = objects;
    },
    SET_OBJECT(state, object) {
        state.object = object;
    },
    SET_OBJECTS_LOADING(state, loading) {
        state.objectsLoading = loading;
    },
    SET_OBJECTS_ERROR(state, error) {
        state.objectsError = error;
    },
    SET_OBJECT_VALUES(state, values) {
        state.objectValues = values;
    },
    SET_OBJECT_VALUE(state, value) {
        state.objectValue = value;
    },
    SET_OBJECT_VALUES_LOADING(state, loading) {
        state.objectValuesLoading = loading;
    },
    SET_OBJECT_VALUES_ERROR(state, error) {
        state.objectValueError = error;
    }
}

export const actions = {
    getInitialDataObjects({commit}) {
        commit("SET_OBJECTS_LOADING", true);
        InitialDataObjectsService.fetchInitialDataObjects()
            .then(objects => commit("SET_OBJECTS", objects))
            .catch(error => commit("SET_OBJECTS_ERROR", error))
            .finally(() => commit("SET_OBJECTS_LOADING", false))
    },
    getInitialDataObject({commit}, object_id) {
        commit("SET_OBJECTS_LOADING", true);
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.fetchInitialDataObject(object_id)
                .then(object => {
                    commit("SET_OBJECT", object);
                    resolve();
                })
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject(error);
                })
        })
    },
    createInitialDataObject({commit}, payload) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.createInitialDataObject(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject();
                })
        })
    },
    updateInitialDataObject({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.updateInitialDataObject(id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject();
                })
        })
    },
    deleteInitialDataObject({commit}, id) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.deleteInitialDataObject(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject();
                })
        })
    },
    getInitialDataObjectValues({commit}, object_id) {
        commit("SET_OBJECT_VALUES_LOADING", true);
        InitialDataObjectsService.fetchInitialDataObjectValues(object_id)
            .then(values => commit("SET_OBJECT_VALUES", values))
            .catch(error => commit("SET_OBJECT_VALUES_ERROR", error))
            .finally(() => commit("SET_OBJECT_VALUES_LOADING", false));
    },
    getInitialDataObjetValue({commit}, {object_id, value_id}) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.fetchInitialDataObjectValue(object_id, value_id)
                .then(value => {
                    commit("SET_OBJECT_VALUE", value);
                    resolve();
                })
                .catch(error => {
                    commit("SET_OBJECT_VALUES_ERROR", error);
                    reject(error);
                })
        })
    },
    createInitialDataObjectValue({commit}, {object_id, payload}) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.createInitialDataObjectValue(object_id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject();
                })
        })
    },
    updateInitialDataObjectValue({commit}, {object_id, value_id, payload}) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.updateInitialDataObjectValue(object_id, value_id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject();
                })
        })
    },
    deleteInitialObjectValue({commit}, {object_id, value_id}) {
        return new Promise((resolve, reject) => {
            InitialDataObjectsService.deleteInitialDataObjectValue(object_id, value_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_OBJECTS_ERROR", error);
                    reject();
                })
        })
    }
}

export const getters = {
    getObjects: state => {
        return state.objects;
    },
    getObject: state => {
        return state.object;
    },
    getObjectValues: state => {
        return state.objectValues;
    },
    getObjectValue: state => {
        return state.objectValue;
    }
}