<template>
  <v-row>
    <v-col md="12">
      <objects-list />
    </v-col>

    <v-dialog
        v-model="createDialog"
        max-width="600px"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            v-bind="attrs"
            v-on="on"
            @click="createDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <object-create-form @success-request="createDialog = false" />
    </v-dialog>

  </v-row>
</template>

<script>
import ObjectsList from "@/components/InitialData/ObjectsList";
import ObjectCreateForm from "@/components/InitialData/ObjectCreateForm";
export default {
  name: "ObjectsListPage",
  components: {ObjectCreateForm, ObjectsList},
  data() {
    return {
      createDialog: false
    }
  },
  mounted() {
    this.$store.dispatch('initialDataObjects/getInitialDataObjects');
  }
}
</script>

<style scoped>

</style>