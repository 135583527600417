<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-select
        v-model="payload.project_id"
        :rules="notNullRule"
        label="Проект"
        :items="projects.map(project => {
            return {value: project.id, text: project.name}
          })"
        required
    ></v-select>

    <v-select
        v-model="payload.object_type_id"
        :rules="notNullRule"
        label="Тип объекта"
        :items="objectTypes.map(objectType => {
            return {value: objectType.id, text: objectType.name}
          })"
        required
    ></v-select>

    <v-select
        v-model="payload.construction_type"
        :items="constructionTypes"
        :rules="notNullRule"
        label="Тип конструкции"
        item-text="name"
        item-value="id"
        required
    ></v-select>

    <v-text-field
        v-model="payload.name"
        :rules="notNullRule"
        label="Наименование объекта"
        required
    ></v-text-field>

    <v-text-field
        v-model="payload.subscriber_base_number"
        :rules="notNullRule"
        label="Номер по абонентской базе"
        required
    ></v-text-field>
    <v-text-field
        v-model="payload.district"
        :rules="notNullRule"
        label="Районное управление"
        required
    ></v-text-field>
    <v-text-field
        v-model="payload.address"
        :rules="notNullRule"
        label="Адрес объекта"
        required
    ></v-text-field>

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="payload.event_at"
            :rules="notNullRule"
            label="Дата и время проведения"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>

      <v-date-picker
          v-model="payload.event_at"
          locale="ru-ru"
          :first-day-of-week="1"
          @input="menu = false"
      ></v-date-picker>
    </v-menu>

    <v-select
        v-model="payload.engineers"
        :items="engineers"
        :rules="notEmptyArrRule"
        label="Специалисты"
        item-text="full_name"
        item-value="id"
        multiple
        required
    ></v-select>

    <v-btn
        color="primary"
        block
        @click="submitForm"
    >Сохранить
    </v-btn>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CreateInventoryObjectForm",
  data() {
    return {
      payload: {
        project_id: null,
        object_type_id: null,
        name: null,
        address: null,
        lat: null,
        lon: null,
        event_at: null,
        engineers: [],
        construction_type: null,
        subscriber_base_number: null,
        district: null
      },
      notNullRule: [
        v => !!v || 'Поле обязательно для заполнения'
      ],
      notEmptyArrRule: [
        v => Boolean(v.length) || 'Поле обязательно для заполнения'
      ],
      valid: true,
      menu: false
    }
  },
  computed: {
    ...mapGetters({
      projects: 'projects/getProjects',
      objectTypes: 'object_types/getObjectTypes',
      coords: 'base_map/getEventClickCoords',
      engineers: 'engineers/getEngineers',
      constructionTypes: 'constructionTypes/getConstructionTypes'
    })
  },
  mounted() {
    this.$store.dispatch('projects/getProjects');
    this.$store.dispatch('object_types/getObjectTypes');
    this.$store.dispatch('constructionTypes/getConstructionTypes');
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.payload.lat = this.coords.lat;
        this.payload.lon = this.coords.lng;
        this.$store.dispatch('inventory_objects/createInventoryObject', this.payload)
            .then(() => {
              this.$store.dispatch('inventory_objects/getInventoryObjects');
              this.$router.push('/inventory-objects');
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
