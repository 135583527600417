<template>
  <v-card>
    <v-card-title>Добавить значение объекту</v-card-title>

    <v-card-text>
      <v-form
          v-model="valid"
          ref="form"
          lazy-validation
      >
        <v-select
            v-model="payload.category"
            :items="categories"
            :rules="notNullRule"
            label="Категория"
            item-text="name"
            item-value="id"
            required
        ></v-select>

        <v-text-field
            v-model="payload.name"
            :rules="notNullRule"
            label="Наименование"
            required
        ></v-text-field>

        <v-select
            v-model="payload.availability"
            :items="InitialDataValue.getAvailabilities()"
            label="Наличие"
            required
        ></v-select>

        <v-select
            v-model="payload.carrier"
            :items="InitialDataValue.getCarriers()"
            label="Носитель"
            required
        ></v-select>

        <v-select
            v-model="payload.uploaded"
            :items="InitialDataValue.getUploadState()"
            label="Статус загрузки"
            required
        ></v-select>

        <v-text-field
            v-model="payload.remark"
            label="Примечание"
            required
        ></v-text-field>

        <v-btn
            color="primary"
            block
            @click="submitForm()"
        >Сохранить
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import InitialDataValue from "@/models/InitialDataValue";

export default {
  name: "ObjectValueCreateForm",
  data() {
    return {
      valid: false,
      notNullRule: [
          v => !!v || 'Поле обязательно для заполнения'
      ],
      payload: {
        category: null,
        name: null,
        availability: null,
        carrier: null,
        uploaded: null,
        remark: null
      }
    }
  },
  computed: {
    ...mapGetters({
      categories: 'initialDataCategories/getCategories',
      object: 'initialDataObjects/getObject'
    }),
    InitialDataValue: () => {
      return InitialDataValue
    }
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('initialDataObjects/createInitialDataObjectValue', {
          object_id: this.object.id,
          payload: this.payload
        })
            .then(() => {
              this.$store.dispatch('initialDataObjects/getInitialDataObjectValues', this.object.id);
              this.$emit('success-request');

              this.payload = {
                category: null,
                name: null,
                availability: null,
                carrier: null,
                uploaded: null,
                remark: null
              }

              this.$refs.form.resetValidation();
            })
      }
    }
  }
}
</script>

<style scoped>

</style>